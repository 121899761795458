$white-beige : $white-beige;
.page-content form[action*="salesforce"] {
	h2 + table {
		margin-top: 15px;
	}

	table {
		padding: 10px;
		display: block;
		background: $white-beige;
		overflow: hidden;

		tr {
			display: block;
			width: 50%;
			float: left;

			&:nth-child(2n) td {
				background: #f5f6f6;
			}

			td {
				display: block;
				border: none;
				font-size: 16px;
				padding-bottom: 4px;
				font-weight: normal;

				input {
					width: 100%;
					padding: 0 12px;
					margin: 0;
					display: inline-block;
					font-family: inherit;
					font-size: 16px;
					line-height: 22px;
					height: 34px;
					font-weight: 400;
					border: 1px solid #f6f8fa;
					outline: none;
					vertical-align: middle;
					border-radius: 6px;
					background-color: #fff;
					box-shadow: inset 1px 1px 5px rgba(0,0,0,.22);
				}

				&:last-child {
					border-right: none;
					padding-top: 0px;
				}
			}
		}
	}

	[type="submit"] {
		color: #fff;
		background: #1a9f46;
		margin-top: 1em;
		margin-bottom: 1em;
		display: inline-block;
		padding: 12px 23px;
		font-size: 12px;
		text-decoration: none;
		line-height: 14px;
		text-align: center;
		text-transform: uppercase;	
		font-family: inherit;
		border: none;
		outline: none;
		cursor: pointer;
		border-radius: 8px;
		transition: background .5s ease;
	}
}

