
/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');*/
$foundation-typography-sans: 'Open Sans','Noto Sans JP', "PingFang SC", "Microsoft YaHei", sans-serif;
$foundation-typography-mono: 'Open Sans','Noto Sans JP', "PingFang SC", "Microsoft YaHei", sans-serif;
$font-family-sans-serif: $foundation-typography-sans !default;
$font-family-monospace: $foundation-typography-mono!default;
$font-size-base: 1.6rem !default;

$h1-font-size:                4rem !default;
$h2-font-size:                2.6rem !default;
$h3-font-size:                2.0rem !default;
$h4-font-size:                1.4rem !default;
$h5-font-size:                1.2rem !default;
$h6-font-size:                $font-size-base !default;

$display1-size:               11rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                1.4rem !default;
$font-size-xs:                ($font-size-base * .875) !default;

//Overwrite bootstrap fonts
$font-family-sans-serif: $foundation-typography-sans;
$font-family-monospace: $foundation-typography-mono;

html {
    font-size: 62.5%;
}
