﻿$linkColor: $primary-blue;
$linkColorHover: $primary-green;
$selectedFilterBackground: $primary-blue;
$selectedFilterFontColor: $white;
$filterHeadBackgroundColor: $primary-blue-lightest;
$filterHeadFontColor: $primary-blue;
$loadMoreBgColor: $primary-green;
$buttonBackgroundColorHover: $primary-blue;
$loadMoreColor: $white;
$borderRadius: 1.6rem;
$mainTagBgColor: $primary-blue;
$mainTagColor: $white;
$tagColor: $secondary-black-light;
$publishDateColor: $secondary-black-light;
$hightlingColor: $primary-green-ch-lightest ;

.tagsearch {
	&-result {

		&-item {
			-webkit-text-decoration: none;
			text-decoration: none;
			color: $linkColor;
			display: flex;
			flex-wrap: wrap;

			& .headline > span {
				background-color: $hightlingColor;
				border-radius: 5px;
				padding: 0 2px;
			}

			h3 {
				@include media-breakpoint-down(lg) {
					font-size: 18px;
				}
			}

			&:hover {
				color: $linkColorHover;
				text-decoration: none;
			}

			&:hover .tagsearch-result-item-tag {
				color: $linkColorHover;
			}

			&:hover .tagsearch-result-item-breadcrumb {
				color: $linkColorHover;
			}

			img {
				width: 100%;
				max-width: 100%;
				height: auto;
			}

			&-breadcrumb {
				font-size: 16px;
				font-weight: 200;
				text-transform: uppercase;
				display: block;
				position: relative;
				color: $publishDateColor;
				padding-bottom: 1rem;

				& > i {
					margin: 0 1rem;
					font-size: 20px;
				}
			}

			&-taglist {
				@include media-breakpoint-up(lg) {
					position: absolute;
					bottom: 5px;
				}
				/*@include media-breakpoint-down(lg) {*/
				display: flex;
				flex-wrap: wrap;
				/*}*/
			}

			&-maintag {
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 0px 0px $borderRadius 0px;
				padding: 5px 10px;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 600;
				background-color: $mainTagBgColor;
				color: $mainTagColor;
			}

			&-tag {
				color: $tagColor;

				&:after {
					content: "|";
					padding: 0 .5rem;
				}

				&:last-child {
					margin-right: 0px;

					&:after {
						content: "" !important;
						padding: 0 !important;
					}
				}
			}

			&-description {
				& > span {
					background-color: $hightlingColor;
					border-radius: 5px;
					padding: 0 2px;
				}
			}
		}
	}
}
