.contact_information {
	&__heading {
		font-weight: 600;
		font-size: 18px;

		@include themify() {
			color: getThemifyVariable('color-headline');
		}
	}



	&__phone {
		a {
			text-decoration: none;

			@include themify() {
				color: getThemifyVariable('color-text');
			}

			&:hover {
				@include themify() {
					color: getThemifyVariable('color-button-text');
				}
			}
		}
	}

	a {
		text-decoration: none;

		@include themify() {
			color: getThemifyVariable('color-text');
		}

		&:hover {
			@include themify() {
				color: getThemifyVariable('color-button-text');
			}
		}
	}

	&__textlink {
		a {
			text-decoration: none;

			@include themify() {
				color: getThemifyVariable('color-text');
			}

			&:hover {
				@include themify() {
					color: getThemifyVariable('color-button-text');
				}
			}
		}
	}
}
.footer_logo {
	max-width: 250px;
}

.footer_identity-image {
	position: absolute;
	right: 0;
}



