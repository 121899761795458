
@media print {
	/** Setting margins */
	$linkcolor: $green;
	
	@page {
		margin: 1cm;
	}

	body {
		font: 13pt Georgia, "Times New Roman", Times, serif;
		line-height: 1.3;
		background: #fff !important;
		color: #000;
	}


	h1 {
		font-size: 24pt;
		
	}

	h2, h3, h4 {
		font-size: 14pt;
		margin-top: 25px;
	}

	a {
		page-break-inside: avoid
	}

	blockquote {
		page-break-inside: avoid;
	}

	h1, h2, h3, h4, h5, h6 {
		page-break-after: avoid;
		page-break-inside: avoid
	}

	img {	
		page-break-inside: avoid;
		page-break-after: avoid;
	}

	table {
		page-break-inside: avoid
	}

	ul, ol, dl {
		page-break-before: avoid
	}

	/* Displaying link color and link behaviour */
	a:link, a:visited, a {
		background: transparent;
		color: $linkcolor;
		font-weight: bold;
		text-decoration: underline;
		text-align: left;
	}

	a {
		page-break-inside: avoid
	}

	a[href^=https]:after {
		content: " < " attr(href) "> ";
	}

	a:after > img {
		content: "";
	}

	article a[href^="#"]:after {
		content: "";
	}

	a:not(:local-link):after {
		content: " < " attr(href) "> ";
	}

	/* Hiding unnecessary elements for the print */
	#footersection, .navbar-legal__links, aside, .herospot, .heroslider__container, .herospot__img, #cookie-information-template-wrapper, #Coi-Renew, #coi-renew-svg, #coiOverlay, video, .topics, .relatednews, .blpItem, .videomodule, .herospot__section, header, .videobox {
		display: none;
	}
	.relatedlist__item {
		max-width: 30%;		
	}
	.relatedlist, .card-deck {
		page-break-before: always;
		page-break-inside: avoid;
	}
	#cookie-information-template-wrapper {
		display: none !important;
	}

	.page-content > article-image {
		max-width:80%;
	}
	footer > .bg-primary-dark {
		background-color: #ffffff;
		color: #000;
	}
	footer {
		position: fixed;
		bottom: 0;
		background-color: transparent;
		color: #000;
		margin-bottom:0!important;
		max-height:50px;

		.bg-primary-dark {
			background-color: #ffffff;
			color: #000;
		}
	}
}
