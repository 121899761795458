﻿$linkColor: $primary-blue;
$publishDateColor: $secondary-black-light;
$linkColorHover: $primary-green;
$tagColor: $secondary-black-light;

.relatednews {


	img {
		display: block;
		width: 100%;
		max-width: 100%;
		height: auto;
		margin-left: 0 !important;
	}

	.relatednews-items {

		div:first-child {
			padding-left: 0 !important;
		}
	}

	&-item {
		padding: 0rem .75rem;

		&:last-child {
			padding-right: 0 !important;
		}

		a {
			text-decoration: none;

			&:hover {
				color: $linkColorHover;

				& .relatednews-item-taglist-tag {
					color: $linkColorHover;
				}

				& .relatednews-item-publishdate {
					color: $linkColorHover;
				}
			}
		}

		figure {
			position: relative;
		}

		&-publishdate {
			font-size: 16px;
			font-weight: 200;
			text-transform: uppercase;
			display: block;
			position: relative;
			color: $publishDateColor;
			padding-bottom: 1rem;
		}


		&-content {
		}

		h3 {
			font-size: 20px;
			font-weight: 500;
			height: 42px;
			margin-bottom: 3rem;
		}

		&-maintag {
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 0px 0px $borderRadius 0px;
			padding: 5px 10px;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 800;
			background-color: $mainTagBgColor;
			color: $mainTagColor;
		}

		&-taglist {
			/*@include media-breakpoint-up(lg) {
				position: absolute;
				bottom: 5px;
			}*/
			margin-top: 2rem;
			display: flex;
			flex-wrap: wrap;

			&-tag {
				color: $tagColor;

				&:after {
					content: "|";
					padding: 0 .5rem;
				}

				&:last-child {
					&:after {
						content: "" !important;
						padding: 0 !important;
					}
				}
			}
		}
	}
}
