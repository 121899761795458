$h1-font-size: $h1-font-size;

.cta-teaser-box_bg {
	$parent: &;
	height: 100%;
	padding: map-get($spacers, 5) 0;
	text-align: center;


	&__text {
		@include themify() {
			color: getThemifyVariable('color-text');
		}

		font-size: 16px;
		line-height: 24px;
		text-align: left;
	}

	&__button {
		@include themify() {
			background-color: getThemifyVariable('color-button-bg');
			color: getThemifyVariable('color-button-text');
		}

		&:hover {
			@include themify() {
				background-color: getThemifyVariable('color-button-bg-hover');
				color: getThemifyVariable('color-button-text');
			}
		}
	}
}

