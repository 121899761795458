.tl-timeline h1, .tl-timeline h2, .tl-timeline h3, .tl-timeline h4, .tl-timeline h5, .tl-timeline h6, .tl-text-content, .tl-text-content p, .tl-timeline .tl-headline-date, .tl-timeline h3.tl-headline-date {
	font-family: "Open Sans", "PingFang SC", "Microsoft YaHei", sans-serif !important;
}

.tl-text h2.tl-headline-title, .tl-text h2.tl-headline {
	color: #004b88 !important;
	font-weight: 100 !important;
}

.tl-attribution{display:none;}
