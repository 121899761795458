﻿.eventslinks {
	.events__schedule__headline {
		padding-bottom:1rem;
		h1, h2, h3, p {
			@include themify() {
				color: getThemifyVariable('color-headline');
			}
		}
	}

	.list-group {
		@include themify() {
			border-bottom-color: getThemifyVariable('color-border');
		}
		border-bottom: 1px solid;
	}
}
