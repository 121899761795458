.navbar-legal {
	font-size: 13px;

	a {
		font-size: 13px;

		@include themify() {
			color: getThemifyVariable('color-text');
		}

		:hover {
			@include themify() {
				color: getThemifyVariable('color-text');
			}

			cursor: pointer;
		}
	}

	p {
		font-size: 13px;

		@include themify() {
			color: getThemifyVariable('color-text');
		}
	}

	@include media-breakpoint-down(sm) {
		display: block;
		width: 100%;
		padding-bottom: 60px;
	}

	&__corporate {
		display: inline-flex;
	}

	&__links {
		display: inline-flex;
		justify-content: flex-end;

		@include media-breakpoint-down(md) {
			justify-content: flex-start;
		}
	}

	.block-corp:after {
		content: '|';
		padding-left: 5px;
		padding-right: 5px;
	}
	.block{
		padding:5px 0;
	}
	.block:after {
		content: '|';
		padding-left: 5px;
		padding-right: 5px;
	}

	.block:last-child:after {
		content: '';
	}
}
