@font-face {
    font-family: 'fontawesome-webfont';
    src:  url('../fonts/fontawesome-webfont.eot?uke224');
    src:  url('../fonts/fontawesome-webfont.eot?uke224#iefix') format('embedded-opentype'),
      url('../fonts/fontawesome-webfont.ttf?uke224') format('truetype'),
      url('../fonts/fontawesome-webfont.woff?uke224') format('woff'),
      url('../fonts/fontawesome-webfont.svg?uke224#fontawesome-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.eot?uke224');
	src: url('../fonts/icomoon.eot?uke224#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?uke224') format('truetype'), url('../fonts/icomoon.woff?uke224') format('woff'), url('../fonts/icomoon.svg?uke224#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'fontawesome-webfont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-checkmark:before {
    content: "\e600";
  }
  .icon-arrow-right:before {
    content: "\e602";
  }
  .icon-arrow-up-down:before {
    content: "\e605";
  }
  .icon-eye:before {
    content: "\e606";
  }
  .icon-share:before {
    content: "\e607";
  }
  .icon-speech:before {
    content: "\e608";
  }
  .icon-globe:before {
    content: "\e622";
  }
  .icon-search:before {
    content: "\e609";
  }
  .icon-menu:before {
    content: "\e60a";
  }
  .icon-resize:before {
    content: "\e60b";
  }
  .icon-pause:before {
    content: "\e60c";
  }
  .icon-play:before {
    content: "\e60d";
  }
  .icon-plus:before {
    content: "\e60e";
  }
  .icon-close:before {
    content: "\e60f";
  }
  .icon-mail:before {
    content: "\e614";
  }
  .icon-google-plus:before {
    content: "\e615";
  }
  .icon-location:before {
    content: "\e616";
  }
  .icon-pinterest:before {
    content: "\e617";
  }
  .icon-twitter:before {
    content: "\e618";
  }
  .icon-facebook:before {
	content: "\e627";
  }
  .icon-linkedin:before {
	content: "\e628";
  }
  .icon-letter:before {
    content: "\e61b";
  }
  .icon-menu_burger:before {
    content: "\e61c";
  }
  .icon-phone:before {
    content: "\e61d";
  }
  .icon-youtube:before {
    content: "\e61f";
  }
  .icon-close-lg:before {
    content: "\e621";
  }
  .icon-globe:before {
    content: "\e622";
  }
  .icon-search_01:before {
    content: "\e623";
  }
  .icon-shoppingcart:before {
    content: "\e624";
  }
  .icon-arrow_rigth_01:before {
    content: "\e625";
  }
  .icon-instagram:before {
    content: "\ea92";
  }
