$blue: $primary-blue;
$header-background-color: $white-dark;
$btn-border-radius: $btn-border-radius !default;
.common-container {
	@include make-row();
}

.stick-bottom {
	right:0;
	left:0;
	bottom:0;
}

.container-fluid {
	padding:0;
}

article {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}

aside {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;

	h2 {
		font-size: 2rem !important;
		font-weight: 300;
	}
}

.page-content {
	word-break: break-word;
}


aside > div {
	margin-bottom: 3rem;
}

aside:nth-child(1) {
	margin-top: 15px;
}

.card-group {
	.card {
		div {
			height: 100%;
		}
	}
}
.card {
	border: 0;
	border-radius: 0;
}



.fixed-top {
	background-color: $header-background-color;
}

#coiOverlay, #Coi-Renew {
	z-index: 1020 !important;
}

.pagemode-preview {
	.fixed-top {
		top: 139px;
	}
}
.pagemode-edit {
	.fixed-top {
		top: 139px;
	}

	main {
		padding-top: 0;
	}

	.container, .container-fluid {
		min-height: 2rem;
	}

	.scEmptyImage {
		max-height: 50%;
		max-width: 50%;
	}

	.scEmptyPlaceholder {
		width: 100%;
	}

}


button{
	&:hover{
	cursor: pointer;
	}
}

.btn {
	white-space: normal !important;
	
	&--pill {
		min-width: 20rem;

		@include media-breakpoint-down(lg) {
			min-width: 17rem;
		}

		margin-top: 2rem;
		text-transform: uppercase;
	}
}


.align-center {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	margin: auto;
}
.align-left {
	float: left;		
}

.align-right {
	float: right;		
}

.font-16 {
	font-size: 16px !important;
}
.font-18 {
	font-size: 18px !important;
}

.font-22 {
	font-size: 22px !important;

	@include media-breakpoint-down(lg) {
		font-size: 18px !important;
	}
}

.font-35 {
	font-size: 35px !important;
}

.font-40 {
	font-size: 40px !important;

	@include media-breakpoint-down(lg) {
		font-size: 25px !important;
	}	
}

.fade-out {
	animation: fadeOut ease 2s;
	-webkit-animation: fadeOut ease 2s;
	-moz-animation: fadeOut ease 2s;
	-o-animation: fadeOut ease 2s;
	-ms-animation: fadeOut ease 2s;
}

.fade-in {
	animation: fadeIn ease 2s;
	-webkit-animation: fadeIn ease 2s;
	-moz-animation: fadeIn ease 2s;
	-o-animation: fadeIn ease 2s;
	-ms-animation: fadeIn ease 2s;
}


/*Rich text editor*/

@media (min-width: 992px) {
	.showindesktop {
		display: block !important;
	}
}

@media (max-width: 992px) {
	.showindesktop {
		display: none !important;
	}
}

.rte-green-btn {
	background-color: #009864 !important;
	color: #ffffff !important;
	border-radius: 1.6rem;
	min-width: 20rem;
	margin-top: 2rem;
	text-transform: uppercase;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1.6rem;
	line-height: 1.5;
}


span.text-small {
	font-size: 13px !important;
}

.text-small {
	font-size: 13px !important;
}


