
$textarea-border-radius: 0.8rem;
$background-color: $white;
$textarea-border-color: #f6f8fa;
$bg-white-bg-color: $white-beige;
$primary-green: $primary-green;

form {
	iframe {
		@include media-breakpoint-down(sm) {
			max-width: 100% !important;
		}
	}

	h1 {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-weight: 300;
		font-size: $h2-font-size;
	}

	.form-sections {
		max-width: 100%;
	}

	.btn {
		border-radius: $btn-border-radius;
		min-width: 20rem;
		padding: .5rem 2rem;
		margin-top: 2rem;
		background-color: $primary-green;
		color: #ffffff;
	}

	.control-label {
		padding-right: 10px;
	}

	.form-group > select {
		border-radius: 6px;
		background-color: $background-color;
		box-shadow: inset 1px 1px 5px rgba(0, 0, 0, .22);
	}

	.text-box {
		margin: 0;
		display: inline-block;
		font-size: 16px;
		line-height: 22px;
		height: 34px;
		font-weight: 400;
		border: 1px solid #f6f8fa;
		outline: none;
		vertical-align: middle;
		border-radius: $textarea-border-radius;
		background-color: $background-color;
		box-shadow: inset 1px 1px 5px rgba(0, 0, 0, .22);
	}

	.form-group > textarea {
		max-width: 100%;
		min-height: 205px;
		resize: none;
		-webkit-appearance: none;
		border: 1px solid #f6f8fa;
		outline: none;
		vertical-align: middle;
		border-radius: $textarea-border-radius;
		background-color: $background-color;
		box-shadow: inset 1px 1px 5px rgba(0, 0, 0, .22);
	}

	.field-validation-error {
		color: $secondary-red;
	}

	.input-group > select {
		border-top-left-radius: 6px !important;
		border-bottom-left-radius: 6px !important;
		margin: 0;
		display: inline-block;
		line-height: 22px;
		height: 34px;
		border: 1px solid #f6f8fa;
		outline: none;
		vertical-align: middle;
		border-radius: $textarea-border-radius;
		background-color: $background-color;
		box-shadow: inset 1px 1px 5px rgba(0, 0, 0, .22);
	}
}

.bootstrap-form {
	height: 100%;
	padding: 3rem 3rem;
	background-color: $bg-white-bg-color;
}

.page-header {
	p {
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}

	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

.form-submit-border {
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}
