﻿.categoryherospot {
	position: relative;
	overflow: hidden;

	@include media-breakpoint-up(lg) {
		min-height: 570px;
	}

	&__wrapper {
		@include media-breakpoint-down(lg) {
			padding: 4rem;
		}
	}

	&__abstract {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-size: 2.2rem;
		margin-bottom: 0;
	}

	&__headline {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-weight: 600;
		font-size: 4.6rem;
		margin-bottom: 1rem;
		line-height: 1.2;

		@media (max-width: 768px) {
			font-size: 3rem;
		}
	}

	&__outline {
		@include themify() {
			color: getThemifyVariable('color-text');
		}
	}

	&__icon {
		height: 100%;
		width: auto;
		position: absolute;

		img {
			overflow-x: hidden;
		}
	}

	&__image {
		text-align: center !important;
		max-height: 500px;

		@include media-breakpoint-down(lg) {
			display:none;
		}

		img {
			@include media-breakpoint-up(xl) {
			}

			max-height: 500px;
			width:auto;
		}


		@include media-breakpoint-up(lg) {
			text-align: right !important;
		}
	}

	&__link {
		text-transform: uppercase;

		a {
			text-decoration: none;

			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}

		i {
			text-decoration: none;

			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}
	}
}
