$link-font-color: $primary-blue;
$background-color: $secondary-black-lightest;
.event {
	&__card {
		margin-bottom: 25px;
		display: inline-block;

		figure {
			width: 100%;

			img {
				width: 100%;
				height: auto;
			}
		}

		&__content {
			background-color: $background-color;
			padding: .7em 1em 1em;

			.header {
				margin: 0 0 .5em;
				font-size: 20px;
				color: $link-font-color;
				font-weight: 500;
				line-height: 1.375;
			}

			.info {
				font-size: 16px;
				line-height: 1.375;

				span {
					width: 60px;
					display: inline-block;
				}
			}

			.location {
				color: #08a041;

				span {
					font-size: 16px;
					padding-right: 10px;
				}

				a {
					color: #08a041;
					font-size: 16px;
				}
			}
		}
	}
}
