$font-color-p: $font-color-p;

.n-article {
	$parent: &;
	overflow: hidden;
	margin-bottom: 1rem;

	@include media-breakpoint-up(lg) {
		margin-bottom: 2rem;
	}

	display: flex;
	flex-direction: row;
	/*&:after {
		content: "";
		flex: 1 1;
		margin: auto 1rem;
		border-style: solid;
		border-width: .5px;
		color: $font-color-p;
	}*/
	&-author {
		display: inline-block;
		font-weight: 600;
		color: $font-color-p;

		&:after {
			content: '\2022'; // bullet
			margin: 0 10px;
			font-weight: bold;
		}
	}

	&-published {
		display: inline-block;
		color: $font-color-p;

		&:after {
			content: '\2022'; // bullet
			margin: 0 10px;
			font-weight: bold;
			color: $font-color-p;
		}
	}

	&-read {
		display: inline-block;
		color: $font-color-p;
	}

	.hr-rule {
		width: 75%;
		height: 1px;
		background-color: $font-color-p;
		flex: 1 1;
		margin: 12px;
	}
}

.n-tags {
	$parent: &;
	/*overflow: hidden;*/
	margin-bottom: 1rem;

	@include media-breakpoint-up(lg) {
		margin-bottom: 2rem;
	}
	/*display: flex;
	flex-direction: row;*/
	&-tag {
		margin-right: 1rem;
		margin-bottom: 1rem;
		display: inline-block;
		padding: .5rem 2rem;
		border: 1px solid;
		border-radius: 20px;
		font-weight: 600;
		font-size: 1.2rem;

		@include themify() {
			border-color: getThemifyVariable('color-text');
			color: getThemifyVariable('color-text');
		}

		@media (max-width: 768px) {
			font-size: 1rem;
		}
	}
}
