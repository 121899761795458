$gray-light: $white-beige;
$blue-dark: $secondary-blue-dark;
$blue: $primary-blue;
$blue-light: $primary-blue-lightest;
$darkgreen: $primary-green;
$red: $secondary-red;
$h4: $h4-font-size ;

.accordion {
	margin-bottom: 5px;
	clear: both;

	@include media-breakpoint-down(md) {
		width: 100%;
	}

	h3 {
		padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 80px;
		border-top: 1px solid rgba(0, 18, 51, 0.125);
		position: relative;
		font-size: 19px;
		line-height: 24px;
		font-weight: 300;
		text-transform: uppercase;
		cursor: pointer;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		color:inherit;

		i {
			padding-left: 0;
			font-size: 20px;

			@include media-breakpoint-up(md) {
				padding-left: 10px;
			}

			&.fa-angle-right {
				margin-left: 5px;
			}

			&.fa-angle-down {
				margin-left: 1px;
			}
		}

		.accordion-open {
			padding-right: 0;
			position: absolute;
			top: 20px;
			right: 0;
			display: block;

			@include media-breakpoint-up(md) {
				padding-right: 25px;
			}
		}

		.accordion-close {
			padding-right: 0;
			position: absolute;
			top: 20px;
			right: 0;
			display: block;

			@include media-breakpoint-up(md) {
				padding-right: 25px;
			}
		}

		i {
			transition: all 0.5s ease;
		}


		span {
			&.accordion-close {
				display: none;
			}
		}

		&.accordion-visible {
			span.accordion-open {
				display: none;
			}

			span.accordion-close {
				display: block;
			}
		}
	}

	.accordion-insides {
		border-top: 0;
		clear: both;
		overflow: hidden;


		&.visible {
			display: block;
			padding-top: 20px;
			border-top: 1px solid $gray-light;

			.accordion-file {
				border-top: 1px solid $gray-light;

				a {
					border: none;
				}
			}
		}

		h1, h2, h3 {
			@include themify() {
				color: getThemifyVariable('color-headline');
			}
		}

		p {
			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}

		a {
			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}

		.accordion-files {
			a {
				&:before {
					content: '\e640';
					margin-right: 5px;
					display: block;
					font-family: 'icomoon';
					font-size: 65px;
				}

				&.pdf a:before {
					content: '\e63c';
					color: $red;
				}

				&.zip a:before {
					content: '\e9c7';
					color: $blue;
				}

				&.ppt a:before {
					content: '\e63f';
					color: $red;
				}

				&.word a:before {
					content: '\e63e';
					color: $blue;
				}

				&.xls a:before {
					content: '\e63d';
					color: $darkgreen;
				}
			}

			@include clearfix;
		}

		.accordion-link {
			a {
				@include themify() {
					color: getThemifyVariable('color-headline');
				}

				&:before {
					color: $blue;
					content: 'share';
					padding-left: 4px;
					display: block;
					font-family: 'icomoon';
					font-size: 63px;
				}
			}
		}

		.accordion-file, .accordion-link {

			@include media-breakpoint-up(md) {
				max-width: 50%;
			}

			&:nth-child(odd) {
				@include media-breakpoint-down(md) {
					margin-left: 0;
				}
			}

			&:nth-child(even) {
			}

			a {
				margin: 3rem;
				text-decoration: none;
			}

			h4, p {
				padding-left: 10px;
				font-family: $foundation-typography-sans;
			}

			h4 {
				margin-bottom: 2px;
				font-size: 1.6rem;
				line-height: 16px;
				font-weight: 500;
				color: $blue-dark;
			}

			p {
				line-height: 15px;
				color: $darkgreen;
			}

			a:before {
				float: left;
				font-family: 'icomoon';
				color: $blue;
			}

			&.pdf a:before {
				content: '\e63c';
				color: $red;
			}

			&.zip a:before {
				content: '\e9c7';
				color: $blue;
			}

			&.ppt a:before {
				content: '\e63f';
				color: $red;
			}

			&.word a:before {
				content: '\e63e';
				color: $blue;
			}

			&.xls a:before {
				content: '\e63d';
				color: $darkgreen;
			}
		}
	}
}
.accordionlist {

	h3 {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid #f0f0ef;
		border-top: 1px solid $gray-light;
		text-transform: uppercase;
		cursor: pointer;
		text-decoration: none;
		-webkit-tap-highlight-color: transparent;
	}

	a {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		text-decoration: none;
		text-transform: uppercase;
		cursor: pointer;
	}
}

.single-accordion {
	font-size: 19px;
	font-weight: 300;
	text-transform: uppercase;

	&:hover {
		transition: all .25s ease-in;
		padding-left: 20px!important;
	}
}

.tpi-website {
	.accordion {
		h3 {
			text-transform: none;
		}
	}

	.accordion-insides {
		a{
			@include themify() {
				color: getThemifyVariable('color-text');
			}

			text-decoration: none;
			font-weight: bold;
			cursor: pointer;
		}

		a:hover {
			text-decoration: underline;

			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}
	}
}

