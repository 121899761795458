$person-module-background: $secondary-black-lightest;
$white: $white ;
$hover-color: $primary-blue;
$primary-green: $primary-green ;
$btn-border-radius: $btn-border-radius !default;
.person-module {
	.person {	
		background-color: $person-module-background;

		.visitcard {
			@include media-breakpoint-up(lg) {
				padding-top: 3rem;
				padding-left: 3rem;
				padding-right: 1rem;
				padding-bottom: 1rem;
			}

			@include media-breakpoint-down(md) {
				padding-top: 1rem;
			}

			i {
				padding-right: 0.4rem;
			}

			div {
				padding-bottom: 0.2rem;
			}
			.info-light{
				font-weight:300;
			}
		}
	}

	.person-image {
		img {
			max-width: 100%;
			height: auto;
		}
	}
	
}

.person-form {
	background-color: $person-module-background;

	.btn {
		border-radius: $btn-border-radius;
		min-width: 20rem;
		padding: .5rem 2rem;
		margin-top: 2rem;
		text-transform: uppercase;
		background-color: $primary-green;
		color: $white;

		&:hover {
			background-color: $hover-color;
			color: $white;
		}
	}
}
.person-headline {
	font-size: 26px;
	font-weight: 300;
	line-height:1.375;
	margin-bottom: 10px;
	margin-top: 10px;
	clear: both;
}
