$green:$primary-green;
$blue:$primary-blue;
$gray-font-color: $font-color-p;
$secondary-blue-lightest: $secondary-blue-lightest;
$primary-green-15: $primary-green-lightest;
$gray-light: $secondary-black-lightest;
$primary-font-color: $primary-green;
$primary-white: $white;

.iconlink {

	.White {
		background-color: $primary-white;

		.h2-icon {
			color: $gray-font-color;

			&:hover {
				color: $blue;
				text-decoration: none;
			}
		}
	}

	.Green {
		background-color: $primary-green-15;

		.h2-icon {
			color: $green;

			&:hover {
				color: $blue;
				text-decoration: none;
			}
		}
	}



	.Dark-Green {
		background-color: $green;
		color: $primary-white;

		.h2-icon {
			color: $primary-white;

			&:hover {
				color: $primary-white;
				text-decoration: none;
			}
		}

		p {
			color: $primary-white;
		}
	}

	.Blue {
		background-color: $secondary-blue-lightest;

		.h2-icon {
			color: $gray-font-color;

			&:hover {
				color: $green;
				text-decoration: none;
			}
		}
	}

	.Dark-Blue {
		background-color: $blue;
		color: $primary-white;

		.h2-icon {
			color: $primary-white;

			&:hover {
				color: $primary-white;
				text-decoration: none;
			}
		}

		p {
			color: $primary-white;
		}
	}

	.Gray {
		background-color: $gray-light;

		.h2-icon {
			color: $gray-font-color;

			&:hover {
				color: $blue;
				text-decoration: none;
			}
		}
	}

	.Sand {
		background-color: $secondary-sand !important;

		.h2-icon {
			color: $gray-font-color;

			&:hover {
				color: $blue;
				text-decoration: none;
			}
		}
	}

	a {
		text-decoration: none;
	}

	.h2-icon {
		display: block;
		margin: 0 0 10px;
		font-size: 18px !important;
		font-weight: 700 !important;
		text-align: center;
	}

	p {
		text-align: center;
	}
}
