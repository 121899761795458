$breadcrumb-gray-lighter: $secondary-black-lightest;
$inactive-breadcrumb: $secondary-gray;
$active-breadcrumb: $active-link-color ;

.navigation-breadcrumb {
	height: 32px;
	width: 100%;
	overflow: hidden;
	background-color: $breadcrumb-gray-lighter;
	font-size: .75em;
	box-shadow: 0px 1px #bfbfbf;
	
	nav {
		border-bottom: solid 1px $gray-light;
		padding-left: 30px;
		z-index: 90;
		line-height: 2.3em;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				vertical-align: middle;

				a {
					display: inline-block;
					vertical-align: middle;
					padding: 2px 0px 2px 20px;
					text-decoration: none;
					white-space: nowrap;
					color: $blue;
				}

				p {
					display: inline-block;
					vertical-align: middle;
					padding: 2px 0px 2px 20px;
					margin: 0;
					text-decoration: none;
					white-space: nowrap;
					font-size: 12px;
					color: $inactive-breadcrumb;
				}
			}
		}

		.nav-1st {
			> li {
				position: relative;
				overflow: hidden;
				background-position: 100% 50%;

				> a {
					margin-right: 2.5em;

					&:hover {
						text-decoration: underline;
					}
				}

				> p {
					margin-right: 2.5em;
				}

				&.current a {
					color: $active-breadcrumb;
				}

				&.has-nav > a {
					background-position: 100% 51%;
					padding-right: 1.75em;

					&.active {
						color: $active-breadcrumb;
					}
				}

				&:after,
				&:before {
					content: '.';
					display: block;
					text-indent: -9999px;
					width: 0;
					height: 0;
					border-top: 18px solid transparent;
					border-bottom: 18px solid transparent;
					position: absolute;
					top: 0;
				}

				&:after {
					border-left: 6px solid #b0aeae;
					right: 0;
				}

				&:before {
					border-left: 6px solid $breadcrumb-gray-lighter;
					right: 1px;
					z-index: 1;
				}

				&:last-child {
					&:before,
					&:after {
						display: none;
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(lg) {
	.navigation-breadcrumb {
		display: none;
	}
}

