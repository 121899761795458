$primary-blue: $primary-blue;
.latestevents {
	.headline {
		display: block;
		padding: 0;
		margin: 0 0 35px;
		text-align: center;
		font-size: 24px;
		font-weight: 300;
	}

	.container {
		border-top: 1px solid #e5e5e5;

		.row {
			border-bottom: 1px solid #e5e5e5;
			min-height: 130px;

			.meta {
				.month {
					font-size: 14px;
				}

				.day {
					font-size: 32px;
					color: #b0aeae;
				}
			}

			.content {
				a {
					text-decoration: none;
				}

				.newsheadline {
					font-size: 16px;
					color: $link-font-color;
					margin: 0;
				}
			}
		}
	}

	.footer {
		
		text-align: right;

		.fa {
			color: $primary-blue;
			font-weight: 100;
		}
	}
}

