$white: $white;


.videolist {
	form {
		background-color: $white !important;

		input[type=text] {
			padding: 5px;
			height: 34px;
			width: 100%;
			float: left;
			border: 1px solid #f6f8fa;
			border-radius: 6px;
			box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.22);
		}
		span, p {
			font-size: 16px;
		}
		.select {
			select {
				padding: 5px;
				height: 34px;
				width: 100%;
				border: 1px solid #f6f8fa;
				border-radius: 6px;
				box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.22);
				overflow: hidden;

				option {
					@include media-breakpoint-down(sm) {
						font-size: 13px;
					}
				}
			}
		}
	}
}
