﻿.eventlink {
	a {
		text-decoration: none;

		@include themify() {
			color: getThemifyVariable('color-text');
		}

		li {
			min-height: 7rem;

			.text {
				font-size: $h2-font-size;
				font-weight: 200;

				@include themify() {
					color: getThemifyVariable('color-text');
				}
			}
		}

		.list-group-item {
			background-color: transparent;
			border-right: none;
			border-left: none;
			border-bottom: none;

			@include themify() {
				border-color: getThemifyVariable('color-border');
			}

			&:hover {
				transition: all .25s ease-in;
				padding-left: 20px;
			}
		}

		li:last-child {
			@include themify() {
				border-bottom-color: getThemifyVariable('color-border');
			}
		}
	}

	.fa-angle-right {
		font-size:3rem;
	}
	.grouping {
		text-align: center;

		span {			
			text-transform: none;
		}
	}

	.category {
		width: 50px;
		font-size: 0.7em;
		text-transform: none;
		font-weight: lighter;
	}

	.numeral {
		font-size: 32px;
		line-height: 38px;
		display: block;
		font-weight: normal;
	}
}

.pagemode-edit {

	li {
		min-height: 7rem;

		.text {
			font-size: $h2-font-size;
			font-weight: 200;

			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}
	}

	.list-group-item {
		background-color: transparent;
		border-right: none;
		border-left: none;
		border-bottom: none;

		@include themify() {
			border-color: getThemifyVariable('color-border');
		}
	}
}



