$h1-font-size: $h1-font-size;

.icon_element {
	text-align: left;
	padding: 4rem;

	@media (max-width: 992px) {
		text-align: center;
	}

	&__image {

		@include media-breakpoint-down(md) {
			margin: 6rem;
		}

		img {
		}
	}

	&__content {
		height: 100%;
		overflow-wrap: break-word;


		@media (max-width: 992px) {
			margin: 1rem;
		}
	}

	&__headline {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-weight: 300;
		font-size: $h1-font-size;

		h2,h3,h4 {
			@include themify() {
				color: getThemifyVariable('color-headline');
			}
		}
	}

	&__text {
		@include themify() {
			color: getThemifyVariable('color-text');
		}

		line-height: 24px;
	}

	&__link {
		text-transform: uppercase;

		a & {
			text-decoration: none;

			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}
	}
}



