.share-menu {
	background: #fff;
	border: 1px solid #ccc;
	position: fixed;
	padding: 4rem;
	width: 215px;
	box-shadow: -1px 2px 4px rgba(0,0,0,0.2);
	display: none;
	z-index: 1031; 

	h1 {
		font-size: 1em;
		font-weight: 300;
	}

	&__close {
		position: absolute;
		right: 11px;
		top: 13px;
		font-size: 14px;
		cursor: pointer;
	}

	&__platforms {
		padding: 0;
		margin: 0 -3em 0 -1em;
		list-style: none;

		&__platform {
			color: #4a4a4a;
			text-decoration: none;
			padding: 6px 1em;
			display: block;
			font-size: 13px;

			&:hover {
				background: #f8f8f8;
			}

			span {
				width: 23px;
				font-size: 1.0em;
				display: inline-block;
				position: relative;
				top: 0px;
			}

			.icon-mail {
				position: relative;
				left: -5px;
				top: 4px;
				line-height: 10px;
				font-size: 20px;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.share-menu {
		display: none;
	}
}
