﻿.range-slider {
	$height: 30px;
	$thumb-height: 13px;
	$track-height: 3px;

	$upper-color: $secondary-blue-pale;
	$lower-color: $secondary-blue-pale;
	$thumb-color: $primary-blue;
	$thumb-hover-color: $secondary-blue-dark;

	$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
	$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

	background-color: transparent;
	display: block;
	appearance: none;
	-webkit-appearance: none;
	width: 100%;
	margin: 0;
	overflow: hidden;
	cursor: pointer;
	padding: 0 5px;

	@include media-breakpoint-down(md) {
		padding: 0 8px;		
	}

	&:focus {
		outline: none;
	}

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: $height;
		background: $lower-background;
	}

	&::-webkit-slider-thumb {
		position: relative;
		-webkit-appearance: none;
		height: $thumb-height;
		width: $thumb-height;
		background: $thumb-color;
		border-radius: 100%;
		border: 0;
		top: 50%;
		margin-top: (-$thumb-height / 2);
		transition: all 250ms;
	}

	&::-moz-range-track,
	&::-moz-range-progress {
		width: 100%;
		height: $height;
		background: $upper-background;
	}

	&::-moz-range-progress {
		background: $lower-background;
	}

	&::-moz-range-thumb {
		-webkit-appearance: none;
		margin: 0;
		height: $thumb-height;
		width: $thumb-height;
		background: $thumb-color;
		border-radius: 100%;
		border: 0;
		transition: all 250ms;
	}

	&::-ms-track {
		width: 100%;
		height: $height;
		border: 0;
		// color needed to hide track marks
		color: transparent;
		background: transparent;
	}

	&::-ms-fill-lower {
		height: $height;
		background: $lower-background;
	}

	&::-ms-fill-upper {
		height: $height;
		background: $upper-background;
	}

	&::-ms-thumb {
		-webkit-appearance: none;
		height: $thumb-height;
		width: $thumb-height;
		background: $thumb-color;
		border-radius: 100%;
		border: 0;
		transition: all 250ms;
		// IE Edge thinks it can support -webkit prefixes
		top: 0;
		margin: 0;
		box-shadow: none;
	}

	&:hover {
		&::-webkit-slider-thumb {
			transform: scale(1.5);
			background-color: $thumb-hover-color;
		}

		&::-moz-range-thumb {
			transform: scale(1.5);
			background-color: $thumb-hover-color;
		}

		&::-ms-thumb {
			transform: scale(1.5);
			background-color: $thumb-hover-color;
		}
	}
}
