﻿.blurpspot {
	height: 100%;

	.content_wrap {
		padding: 3rem;
	}

	&__button {
		@include themify() {
			background-color: getThemifyVariable('color-button-bg');
			color: getThemifyVariable('color-button-text');
		}

		&:hover {
			@include themify() {
				background-color: getThemifyVariable('color-button-bg-hover');
				color: getThemifyVariable('color-button-text');
			}
		}

		margin: 1rem auto;
	}

	&__headline {
		font-weight:500;
		@include themify() {
			color: getThemifyVariable('color-headline');
		}
	}

	&__text {
		font-size: $font-size-sm;

		@include themify() {
			color: getThemifyVariable('color-text');
		}
	}

	&__img {
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
}

	article {
		.blurpspot {
			.content_wrap {
				padding: 1.5rem;
			}

			&__headline {
				font-size: 1.8rem;
			}
		}
	}

	aside {
		.blurpspot {
			height: auto;
			width: 100%;
			padding: 0 !important;

			h2 {
				font-size: 2rem;
				font-weight: 500;
				clear: both;
				line-height: 1.375em;
			}

			&__button {
				min-width: 100%;
			}
		}
	}
