
$gray-light: $font-color-p;
$gray: #7f7f7f;
$white: #ffffff;
$blue-font: #004b88;
$white-darker: #f6f8fa;
$light-blue: #dbe9f5;
$border-color:#dee2e6;

.product-calc {
	div.box {
		display: none;
	}

	.col-md-12 {
		@include media-breakpoint-down(md) {
			padding: 0!important;
		}
	}

	#dropdown-filter {
		width: 100%;
		text-transform: uppercase;
		font-weight: 100;
		font-size: 20px;
		border-radius: inherit;
		background-color: $white;
		padding: 2rem;
		border-bottom: 1px solid $border-color;
	}

	#product-calc-list.dropdown-menu {
		left: 29% !important;

		&.show {
			left: 29% !important;
		}
	}

	.nav-tabs {
		-webkit-animation: scale-up-hor-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
		animation: scale-up-hor-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

		.nav-link {
			border: 1px solid transparent;
			border-top-left-radius: .25rem;
			border-top-right-radius: .25rem;
			text-transform: uppercase;
			font-weight: 100;
			font-size: 20px;
			color: #212529;

			&.active {
				background-color: $light-blue;
			}
		}
	}

	.card {
		display: none;
	}

	.show {

		.card {
			display: flex;
			-webkit-animation: scale-up-hor-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
			animation: scale-up-hor-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;


			.card-title {
				text-transform: uppercase;
				font-weight: 400;
			}
		}

		.card-img-top {
			height: auto;
		}
	}

	.btn[aria-expanded="true"] {
		background-color: #f6f8fa;
	}

	.btn[aria-expanded="false"] {
		background-color: transparent;
	}

	button {
		text-transform: uppercase !important;
		text-decoration: none;
		display: inline-block;
		padding: 10px;
		position: relative;

		@include media-breakpoint-up(md) {
			padding: 15px 20px;
		}
	}

	button:after {
		bottom: 0;
		content: "";
		display: block;
		height: 2px;
		left: 50%;
		position: absolute;
		background: $gray;
		transition: width 0.3s ease 0s, left 0.3s ease 0s;
		width: 0;
	}

	button:hover:after {
		width: 100%;
		left: 0;
	}

	h4 {
		font-weight: 100;
		font-size: 16px;
	}

	.product-calc-list-items {
		min-height: 650px;

		.btn {
			border: 1px solid $gray;
			border-radius: inherit;
			margin: 0.2rem;

			@include media-breakpoint-up(md) {
				margin: 1rem;
			}
		}

		.divider {
			border-top: 1px solid $gray;
			padding: 1rem 0 0 0;

			@include media-breakpoint-up(md) {
				padding: 2rem 0 0 0;
			}

			small {
				padding-left: 1rem;
			}
		}

		.show {
			@include media-breakpoint-down(md) {
				margin-top: 1rem;
			}
		}
	}


	.btn.active, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
		outline: none !important;
		box-shadow: none !important;
	}

	.tree {

		li {
			list-style: none;
		}
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-14 10:20:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-hor-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-hor-center {
	0% {
		-webkit-transform: scaleX(0.4);
		transform: scaleX(0.4);
	}

	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

@keyframes scale-up-hor-center {
	0% {
		-webkit-transform: scaleX(0.4);
		transform: scaleX(0.4);
	}

	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

