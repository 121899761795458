.address_information {
	&__heading {
		font-weight: 600;
		font-size: 18px;

		@include themify() {
			color: getThemifyVariable('color-headline');
		}
	}

	a {
		text-decoration: none;

		@include themify() {
			color: getThemifyVariable('color-text');
		}

		&:hover {
			@include themify() {
				color: getThemifyVariable('color-button-text');
			}
		}
	}
}

