$heading-color: $primary-font-h1;
$abstract-color: $secondary-black-light;

.mw-80 {
	max-width: 80%;
}

.modulecatalog-headline {
	color: $heading-color;
	font-weight: 300;

	@include media-breakpoint-down(md) {
		padding-top: 1rem !important;
		font-size: 3rem;
	}
}

.modulecatalog-abstract {
	font-weight: 300;
	color: $abstract-color;
	font-size: 2.6rem;

	@include media-breakpoint-down(md) {
		font-size: 2.2rem;
	}
}

.navbar-modulecatalog {
	display: block;

	h3 {
		font-size: 2rem;
		font-weight: 300;
		clear: both;
		line-height: 1.375em;

		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		margin-bottom: 1rem;
	}
}

.nav-modulecatalog {
	flex-direction: column !important;
	font-size: 1.3rem;
	line-height: 1.5rem;
	text-decoration: none;
	/*color: #4a4a4a;*/
	&-item {
		margin-bottom: 1rem;



		.active {
			color: $item-active-color;
		}

		.active:hover {
			color: $item-active-color;
		}
	}

	a {
		color: $item-color;
		text-decoration: none;
	}

	a:hover {
		color: $item-active-color;
		text-decoration: none;
	}
	/*&-link {
		color: #4a4a4a;
	}

	&-link:hover {
		color: #4a4a4a;
		text-decoration: none;
	}*/
	&-icon {
		color: $icon-color;
		font-weight: 100;

		active {
			color: $icon-active-color;
		}
	}
}
