//OVERWRITE BOOTSTRAP DEFAULTS
$blue:						map-get(map-get(map-get($foundation-palette, primary), blue), base);
$green:						map-get(map-get(map-get($foundation-palette, primary), green), base);
$white:						map-get(map-get(map-get($foundation-palette, primary), white), base);
$black:						map-get(map-get(map-get($foundation-palette, secondary), black), base);
$yellow:					map-get(map-get(map-get($foundation-palette, secondary), yellow), base);
$red:						map-get(map-get(map-get($foundation-palette, secondary), red), base);
$gray:						map-get(map-get(map-get($foundation-palette, secondary), black), light);


$primary-blue:              $blue;
$primary-blue-lightest:		map-get(map-get(map-get($foundation-palette, primary), blue), lightest);

$primary-green:				$green;
$primary-ch-green:			map-get(map-get(map-get($foundation-palette, primary), green), ch-base);
$primary-green-lightest: map-get(map-get(map-get($foundation-palette, primary), green), lightest);
$primary-green-ch-lightest: map-get(map-get(map-get($foundation-palette, primary), green), ch-lightest);

$white-dark:				map-get(map-get(map-get($foundation-palette, primary), white), dark);
$white-beige:				map-get(map-get(map-get($foundation-palette, primary), white), beige);

$secondary-blue:			map-get(map-get(map-get($foundation-palette, secondary), blue), base);
$secondary-blue-lightest:	map-get(map-get(map-get($foundation-palette, secondary), blue), lightest);
$secondary-blue-ch-lightest: map-get(map-get(map-get($foundation-palette, secondary), blue), ch-lightest);

$secondary-blue-dark:		map-get(map-get(map-get($foundation-palette, secondary), blue), dark);
$secondary-blue-ch-dark:    map-get(map-get(map-get($foundation-palette, secondary), blue), ch-dark);
$secondary-blue-pale:		map-get(map-get(map-get($foundation-palette, secondary), blue), pale);
$secondary-blue-opacityblue: map-get(map-get(map-get($foundation-palette, secondary), blue), opacityblue);
$secondary-blue-darkest-with-opacity: rgba(0, 18, 51, 0.80);

$secondary-green:			map-get(map-get(map-get($foundation-palette, secondary), green), base);
$secondary-green-lightest:	map-get(map-get(map-get($foundation-palette, secondary), green), lightest);

$secondary-red:				map-get(map-get(map-get($foundation-palette, secondary), red), base);
$secondary-red-lightest:	map-get(map-get(map-get($foundation-palette, secondary), red), lightest);

$secondary-yellow:			map-get(map-get(map-get($foundation-palette, secondary), yellow), base);
$secondary-yellow-lightest: map-get(map-get(map-get($foundation-palette, secondary), yellow), lightest);

$secondary-orange:			map-get(map-get(map-get($foundation-palette, secondary), orange), base);
$secondary-orange-lightest: map-get(map-get(map-get($foundation-palette, secondary), orange), lightest);

$secondary-pink:			map-get(map-get(map-get($foundation-palette, secondary), pink), base);
$secondary-pink-lightest:	map-get(map-get(map-get($foundation-palette, secondary), pink), lightest);

$secondary-purple:			map-get(map-get(map-get($foundation-palette, secondary), purple), base);
$secondary-purple-lightest: map-get(map-get(map-get($foundation-palette, secondary), purple), lightest);

$secondary-turquoise: map-get(map-get(map-get($foundation-palette, secondary), turquoise), base);
$secondary-turquoise-lightest: map-get(map-get(map-get($foundation-palette, secondary), turquoise), lightest);

$secondary-brown:			map-get(map-get(map-get($foundation-palette, secondary), brown), base);
$secondary-brown-lightest:	map-get(map-get(map-get($foundation-palette, secondary), brown), lightest);

$secondary-gray:			map-get(map-get(map-get($foundation-palette, secondary), gray), base);
$secondary-gray-lightest:	map-get(map-get(map-get($foundation-palette, secondary), gray), lightest);
$gray-ch-4:					map-get(map-get(map-get($foundation-palette, secondary), gray), ch-4);

$secondary-sand:			map-get(map-get(map-get($foundation-palette, secondary), sand), base);

$secondary-black:			map-get(map-get(map-get($foundation-palette, secondary), black), base);
$secondary-black-light:		map-get(map-get(map-get($foundation-palette, secondary), black), light);
$secondary-black-lighter:	map-get(map-get(map-get($foundation-palette, secondary), black), lighter);
$secondary-black-lightere:	map-get(map-get(map-get($foundation-palette, secondary), black), lightere);
$secondary-black-pale:		map-get(map-get(map-get($foundation-palette, secondary), black), pale);
$secondary-black-lightest:	map-get(map-get(map-get($foundation-palette, secondary), black), lightest);

