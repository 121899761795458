$primary-blue: $primary-blue;
$primary-green: $primary-green;

.news {

	&__list {

		&__item {

			> a {
				text-decoration: none;
				color: $primary-blue !important;

				&:hover {
					color: $primary-green !important;
				}
			}

			&_image {
				img {
					width: 100%;
					height: auto;
				}
			}
			
			&__content {
				&:hover {
					p, h3 {
						color: $primary-green;
					}
				}

				&__meta {
					font-size: 16px;
					font-weight: 200;
					text-transform: uppercase;
					display: block;
					position: relative;
					color: #404040;

					&:hover {
						color: $primary-green;
					}
				}
			}
		}
		&__spinner {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100px;
			font-size: 48px;
			margin-top: 15rem;
			opacity: 40%
		}
	}
}
