
	$primary-green-color: $primary-green;		
	$white: $white;
	$blue-link-color: $primary-blue;
	$dark-blue: $secondary-blue-dark;
	$border-color: $secondary-black-lightest;
	$box-shadow: $secondary-black-lightere;
	$tertiary-item-color: $secondary-black-light;
	$blue-pale: $secondary-blue-pale;
	$primary-nav-background: $primary-navigation-background-color;
	$service-nav-background: $service-navigation-background-color;

#navigation {
	min-height: 90px;
	background-color: $white;
	-webkit-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
	-moz-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
	box-shadow: 0px 4px 2px -2px rgba($box-shadow, .5);

	@media (max-width: 1199px) {
		z-index: 9999999999;
	}

	@media (max-width: 1199px) {
		#primarynavigation {
			margin-top: 2rem;
			height: 80vh;
			align-items: flex-start;
			overflow-y: auto;
			overflow-x: hidden;
			-ms-overflow-style: none; /* hide scrollbar in IE and Edge */
			scrollbar-width: none; /* hide scrollbar in Firefox */
			z-index: 9999999999;

			.desktop {
				display: none !important;
			}

			&::-webkit-scrollbar {
				display: none; /* Hide scrollbar for Chrome, Safari and Opera */
			}
		}
	}

	@media (min-width: 1199px) {
		#primarynavigation {
			.mobile {
				display: none !important;
			}
		}
	}

	.navbar {
		&-nav {
			.dropdown-menu {
				float: left;
			}
		}

		a {
			text-decoration: none;
		}

		@include media-breakpoint-down(lg) {
			padding: 20px;
		}

		.navbar-brand {
			z-index: 1001;
			position: absolute;
			left: 25px;
			top: 15px;
			display: block;

			@include media-breakpoint-up(xl) {
				left: 35px;
				top: 30px;
			}

			.desktop-logo {
				max-width: 140px;
				width: 140px;
				height: auto;
			}
		}

		.service-icons {
			display: flex;

			a, button {
				color: $tertiary-item-color;
			}
		}

		.service-icons-desktop {
			position: absolute;
			right: 20px;
			top: 0px;
			display: flex;
			align-items: center;

			a, button {
				color: $tertiary-item-color;
			}
		}

		.btn, button {
			border: none;
			background: none;

			&.mobile {
				line-height: 1;
				padding-top: 0;
				padding-bottom: 0;
				padding-left: 5rem;
				padding-right: 1rem;
			}

			&:focus {
				outline: none !important;
				box-shadow: none !important;
			}

			&:active {
				border: none;
				outline: none !important;
				box-shadow: none !important;
			}
		}

		.navbar-icon-dropdown {
			color: $white;

			.dropdown-menu {
				display: none;
				z-index: 1003;
				min-width: 16rem;
				font-size: 1.6rem;
				color: $white;
				list-style: none;
				background-color: $primary-nav-background;
				border-radius: 0;
				-webkit-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
				-moz-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
				box-shadow: 0px 4px 2px -2px rgba($box-shadow, .5);
				padding: 0 !important;
				left: -90px;


				.region-links:last-child {
					border-top: solid 1px rgba($box-shadow, .5);
				}

				.dropdown-item {
					color: $white;

					&:hover {
						background-color: $service-nav-background;
						color: $primary-green-color;
					}
				}

				&.show {
					display: block;

					&:hover {
						color: $primary-green-color;
						font-weight: 600;
					}
				}
			}
		}

		.navbar-toggler {
			color: $blue-link-color;
			z-index: 1002;
			position: relative;
		}

		.service-nav {

			.primary-link {
				color: $blue-link-color;
				font-weight: 600;
				line-height: 16px;
				position: relative;

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}

				&:hover {
					color: $primary-green-color !important;
				}

				&-box {
					padding: 2rem;
					right: -300px;
					background-color: $dark-blue;

					a {
						color: $white;
						padding-right: 5px;
						transition: padding-right .2s ease;
						font-weight: 600;

						&:hover {
							padding-right: 20px;
						}
					}

					.fa {
						&:before {
							color: $white;
						}
					}


					p {
						color: $white;
					}
				}
			}

			.secondary-link {
				padding-top: 1rem;
				color: $dark-blue;
				font-weight: 600;
				padding-right: 5px;
				transition: padding-right .2s ease;

				&:hover {
					color: $primary-green-color;
					padding-right: 10px;
					transition: padding-right .2s ease;
				}

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}
			}

			.tertiary-link {
				color: $dark-blue;
				font-weight: 200;

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}

				&:hover {
					color: $primary-green-color !important;
				}
			}

			.service-link {
				color: $tertiary-item-color;
				position: relative;

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}

				@include media-breakpoint-down(lg) {
					padding-top: 0;
					padding-bottom: 1px;
				}

				&:hover {
					color: $primary-green-color;
				}
			}

			.sub-nav {
				&.nav-item {
					&.show {
						@include media-breakpoint-up(xl) {
							.service-link:before {
								border: solid $white;
								content: " ";
								position: absolute;
								pointer-events: none;
								border-color: transparent;
								border-top-color: $white;
								border-width: 18px;
								z-index: 1048;
								bottom: -61px;
								right: 30%;
							}
						}
					}

					&.isSelected {
						.service-link {
							color: $primary-green-color !important;
							font-weight: 600;
						}
					}
				}

				.dropdown-menu {
					@include media-breakpoint-up(xl) {
						top: 75px;
					}

					.close {
						color: $blue-link-color;

						span {
							color: $blue-link-color;
						}
					}
				}
			}
		}

		.primary-nav {
			.primary-link {
				color: $blue-link-color;
				font-weight: 600;
				line-height: 16px;
				position: relative;

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}

				&:hover {
					color: $primary-green-color !important;
				}

				&-box {
					background-color: $primary-green-color;
					padding: 2rem;
					right: -300px;


					a {
						color: $white;
						padding-right: 5px;
						font-weight: 600;
						transition: padding-right .2s ease;

						&:hover {
							padding-right: 10px;
						}
					}

					p {
						color: $white;
					}
				}
			}

			.secondary-link {
				padding-top: 1rem;
				color: $white;
				font-weight: 600;
				padding-right: 5px;
				transition: padding-right .2s ease;


				&:hover {
					padding-right: 10px;
					color: $primary-green-color;
					transition: padding-right .2s ease;
				}

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}
			}

			.tertiary-link {
				color: $white;
				font-weight: 200;

				&:hover {
					color: $primary-green-color;
				}

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}

				&:hover {
					color: $primary-green-color !important;
				}
			}

			.sub-nav {
				.dropdown-menu {
					.close {
						color: $blue-link-color;

						span {
							color: $blue-link-color;
						}
					}
				}
			}
		}

		.sub-nav {
			position: static !important;

			@include media-breakpoint-only(lg) {
				padding-top: 1.2rem;
				padding-bottom: 1.2rem;
				border-bottom: 1px solid $border-color;
			}

			@include media-breakpoint-down(md) {
				padding-top: 0.7rem;
				padding-bottom: 0.7rem;
				padding-left: 0;
				padding-right: 0;
				border-bottom: 1px solid $border-color;
			}

			@include media-breakpoint-up(md) {
				padding: 1rem;
			}

			&.nav-item {
				.icon-container {
					i {
						-moz-transition: all 0.5s ease;
						-webkit-transition: all 0.5s ease;
						-o-transition: all 0.5s ease;
						transition: all 0.5s ease;
					}

					.fa {
						font-size: 30px;

						@include media-breakpoint-down(md) {
							font-size: 25px;
						}
					}

					.fa-angle-right {
						padding-right: 1rem;

						@include media-breakpoint-down(xl) {
							position: absolute;
							right: 3px;
							top: 0px;
						}
					}
				}

				&.show {
					@include media-breakpoint-up(xl) {
						.primary-link {
							&:before {
								border: solid $white;
								content: " ";
								position: absolute;
								pointer-events: none;
								border-color: transparent;
								border-top-color: $white;
								border-width: 18px;
								z-index: 1048;
								bottom: -50px;
								right: 40%;
							}

							&-box {
								position: absolute;
								bottom: 0;
								right: 0;
								max-height: 12rem;
								line-height: 1.2;
								-webkit-animation: slide-in-right 1s ease-out both;
								animation: slide-in-right 1s ease-out both;
							}
						}
					}

					@include media-breakpoint-down(xl) {
						.icon-container {
							i {
								transform: rotate(-180deg);
							}

							.btn {
								&:focus {
									box-shadow: none;
								}

								&:active {
									border: none;
									box-shadow: none;
								}
							}

							&button {
								background-color: $tertiary-item-color;
								border: none;
								color: white;
								padding: 12px 16px;
								font-size: 16px;
								cursor: pointer;
								border-radius: 1px;

								&:active {
									border: none;
									box-shadow: none;
								}
							}

							.fa-angle-down {
								transform: rotate(-180deg);

								&:active {
									border: none;
									box-shadow: none;
								}
							}
						}
					}
				}
			}

			&.isSelected {
				.primary-link {
					color: $primary-green-color !important;
				}

				.service-link {
					color: $primary-green-color !important;
				}
			}

			.close {
				position: absolute;
				right: 48px;
				z-index: inherit;
				top: 40px;

				span {
					font-size: 3rem;
					font-weight: 100;
				}
			}
		}

		.secondary-nav {
			padding: 20px 20px;
			width: 100%;
			margin: 0;

			&.dropdown-menu {
				@include media-breakpoint-down(xl) {
					border: none;
				}

				&.show {
					@include media-breakpoint-up(xl) {
						border-right: none;
						border-left: none;
					}
				}
			}

			.dropdown-item {
				padding-top: 2rem;
				padding-bottom: 2rem;

				&:active {
					text-decoration: none;
					background-color: transparent;
				}

				&:hover {
					text-decoration: none;
					background-color: transparent;
				}
			}

			.primaryblock {
				background-color: $primary-nav-background;
				color: $white;
				padding: 3rem;

				@include media-breakpoint-up(xl) {
					padding: 7rem;
				}
			}

			.serviceblock {
				background-color: $service-nav-background;
				color: $font-color-p;
				padding: 3rem;

				@include media-breakpoint-up(xl) {
					padding: 7rem;
				}
			}
		}

		.secondary-level {
			padding-bottom: 2rem;
		}

		.tertiary-level {

			.tertiary-item {
				padding-bottom: 1rem;
			}
		}
	}
	/*search*/
	.navbar-search {
		position: absolute;
		top: 11rem;
		right: 0rem;
		width: 20%;

		@include media-breakpoint-only(lg) {
			width: 40%;
		}

		@include media-breakpoint-down(lg) {
			top: 6.5rem;
			width: 100%;
		}

		.fa-search {
			color: $white;
		}

		form {
			display: none;
			background: $primary-nav-background;
			text-align: center;
			padding: 2rem;
			-webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
			animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
		}

		input {
			border: none;
			background: none;
			color: $white;
			outline: none;
			width: 80%;
			font-size: 18px;
			font-weight: 300;
		}

		button {
			background: none;
			border: none;
		}

		input::-webkit-input-placeholder {
			color: $white;
			font-weight: 300;
		}
	}
	/* ANIMATED Toggler */
	.navbar-toggler {
		&:focus, &:active {
			outline: 0;
		}

		span {
			display: block;
			background-color: $tertiary-item-color;
			height: 3px;
			width: 25px;
			margin-top: 4px;
			margin-bottom: 4px;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			position: relative;
			left: 0;
			opacity: 1;
			transition: all 1s ease;

			&:nth-child(1), &:nth-child(3) {
				-webkit-transition: transform 1s ease;
				-moz-transition: transform 1s ease;
				-o-transition: transform 1s ease;
				transition: transform 1s ease;
			}
		}

		&:not(.collapsed) {
			span {
				&:nth-child(1) {
					position: absolute;
					left: 5px;
					top: 5px;
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
					opacity: 0.8;
				}

				&:nth-child(2) {
					height: 2px;
					opacity: 0;
					transform: translate(150%,-50%);
				}

				&:nth-child(3) {
					position: absolute;
					left: 5px;
					top: 5px;
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
					opacity: 0.8;
				}
			}
		}
	}

	.slide-in-right {
		-webkit-animation: slide-in-right 2s ease-out both;
		animation: slide-in-right 2s ease-out both;
	}
}




	

