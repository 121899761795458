$backgroundgray: $white-beige;
$gray-hover: #e2e2e2;
$darkgreen: $primary-green;
$link-font-color: $primary-blue;

.announcements {
	&.pdf a:before {
		content: '\e63c';
		color: #da2220;
		float: right;
	}

	&.zip a:before {
		content: '\e9c7';
		color: $link-font-color;
		float: right;
	}

	&.ppt a:before {
		content: '\e63f';
		color: #da2220;
		float: right;
	}

	&.word a:before {
		content: '\e63e';
		color: $link-font-color;
		float: right;
	}

	&.xls a:before {
		content: '\e63d';
		color: $darkgreen;
		float: right;
	}

	a {
		&:before {
			content: '\e640';
			margin-right: 5px;
			display: block;
			font-family: 'icomoon';
			font-size: 65px;
			color: $link-font-color;
			float: right;
		}
	}
}

.anchor--back-to-top {
	display: inline-block;
	padding: 9px 17px;
	margin-right: 6px;
	margin-top: 15px;
	margin-bottom: 10px;
	background: $backgroundgray;
	text-decoration: none;
	font-size: 13px;
	color: $link-font-color;
	transition: all .25s ease;
	cursor: pointer;

	&:hover {
		background-color: $gray-hover;
	}
}

	.list-group {
		&.anchor-nav {
			padding-top: 0 !important;
		}
	}


.anchor-nav {
	display: block;

	&__list {
		padding: 0;
		margin: 0 0 25px;

		&__item {
			display: inline-block;
			padding: 0;
			margin: 0;
			list-style-type: none;

			a {
				display: inline-block;
				padding: 9px 19px;
				margin-right: 6px;
				margin-bottom: 10px;
				background-color: $backgroundgray;
				text-decoration: none;
				font-size: 13px;
				color: $link-font-color;
				transition: all 0.25s ease;

				&:hover {
					background-color: $gray-hover;
				}
			}
		}
	}
}
