
.infobox {
	padding: 3rem;

	@include media-breakpoint-up(md) {
		padding: 3rem 0;
	}

	&__block {
		padding: 1rem;
		min-height: 140px;

		@include themify() {
			border-bottom: solid 1px getThemifyVariable('color-border');
			border-right: 0 !important;
		}

		@include media-breakpoint-up(md) {
			border-bottom: none !important;
			min-height: 200px;

			&:after {
				border-bottom: none !important;
			}

			@include themify() {
				border-right: 1px solid getThemifyVariable('color-border');
				border-bottom: 0 !important;
			}
		}

		&:last-child {
			border-right: 0 !important;
			border-bottom: 0 !important;

			&:before, &:after {
				display: none;
			}
		}

		.card {
			background-color: transparent !important;
			padding: 0;

			.text-center {
				@include themify() {
					color: getThemifyVariable('color-headline');
				}
			}
		}

		&_element {
			text-align: center;
		}

		&_icon_text {
			display: block;
		}

		&_headline {
			text-align: center;
			word-break: break-word;

			@include themify() {
				color: getThemifyVariable('color-headline');
			}

			font-weight: $font-weight-light;
			font-size: $h2-font-size;
		}

		&_text {
			text-align: center;
			font-size: $font-size-sm;
			word-break: break-word;

			@include themify() {
				color: getThemifyVariable('color-headline');
			}
		}

		.card-body {
			@include media-breakpoint-down(md) {
				padding: 0;
			}
		}

		&_icon {
			max-height: 90px;
			width: auto;
			margin: auto;
		}

		&_animationicon {
			max-width: 125px;
			height: auto;
		}
	}

	&__poptext {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-size: 1.6rem;
		font-weight: $font-weight-light;
		line-height: $h3-font-size * 1.2;
		word-break: break-word;

		@include media-breakpoint-up(md) {
			font-size: $h3-font-size;
		}

		&-larger {
			@include themify() {
				color: getThemifyVariable('color-headline');
			}

			font-weight: 700;
			font-size: 12.5rem;
			line-height: 0.8;
			word-break: break-word;
		}

		&-h1 {
			@include themify() {
				color: getThemifyVariable('color-headline');
			}

			font-size: 3.5rem;
			font-weight: $font-weight-bold;
			word-break: break-word;
			margin: 0;

			@include media-breakpoint-up(md) {
				font-size: $display2-size;
			}
		}

		&-h2 {
			@include themify() {
				color: getThemifyVariable('color-headline');
			}

			font-size: 3.2rem;
			font-weight: $font-weight-bold;
			word-break: break-word;
			margin: 0;

			@include media-breakpoint-up(md) {
				font-size: $display3-size;
			}
		}

		&-h3 {
			@include themify() {
				color: getThemifyVariable('color-headline');
			}

			font-size: 3rem;
			font-weight: $font-weight-bold;
			word-break: break-word;
			margin: 0;

			@include media-breakpoint-up(md) {
				font-size: $display4-size;
			}
		}

		&__unit {
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			word-break: break-word;
			font-size: 2.3rem;

			@include themify() {
				color: getThemifyVariable('color-headline');
			}

			@include media-breakpoint-up(md) {
				font-size: 2.5rem;
			}
		}
	}
}
article {
	.infobox {
		&__block {
			p {
				line-height: normal;
			}
		}
	}
}

.tpi-website {
	.infobox {
		padding: 1rem;

		@include media-breakpoint-up(md) {
			padding: 0;
		}

		&__block {
			padding: 1.5rem !important;
			max-height: 175px !important;
			min-height: 130px !important;

			@include media-breakpoint-up(md) {
				padding: 0 0.5rem !important;
				max-height: 175px !important;
				min-height: 130px !important;
				margin: 1rem 0;
			}
		}
	}
}
