.stockticker {
	display: block;
	height:40px;
	iframe{
		height:100%;
	}

	&__editormode {
		min-height:20px;
		background: $gray-light;
	}
}
