$green: $primary-ch-green;
$primary-green: $primary-ch-green;
$primary-green-lightest: $primary-green-ch-lightest;
$secondary-blue-lightest: $secondary-blue-ch-lightest;


$secondary-blue-dark: $secondary-blue-ch-dark;

$secondary-black-lightest: $secondary-black-pale;

$primary-font-h1:			$primary-blue;
$gray-light:				$secondary-black-lightere;
$font-color-p:				$secondary-black;
$quote-default-color:		$primary-blue;
$primary-navigation-background-color: $secondary-blue-dark;
$service-navigation-background-color: $secondary-black-lightest;
$active-link-color:	$primary-green;
$spacer: 1rem !default;
$spacers:					() !default;
$spacers:					map-merge((
								  6: ($spacer * 6),
								  7: ($spacer * 7),
								  8: ($spacer * 8),
								  9: ($spacer * 9),
								  10: ($spacer * 10),
								  11: ($spacer * 11),
								  12: ($spacer * 12),
								  13: ($spacer * 13),
								  14: ($spacer * 14),
								  15: ($spacer * 15),
								  16: ($spacer * 16),
								  17: ($spacer * 17),
								), $spacers);

$btn-border-radius:           1.6rem !default;







