$triangle-border-size: 0.5rem !default;
$triangle-left-pos: 10% !default;

.bg-primary {
	background-color: $primary-blue !important;
	color: $white !important;
}

.bg-white {
	background-color: $white !important;
	color: $primary-blue !important;
}

.bg-primary-transparent {
	background-color: $secondary-blue !important;
	color: $white !important;
}

.bg-primary-light {
	background-color: $secondary-blue-lightest !important;
	color: $primary-blue !important;
}

.bg-primary-dark {
	background-color: $secondary-blue-dark !important;
	color: $white !important;
}

.bg-primary-dark-opacity {
	background-color: $secondary-blue-dark !important;
	color: $white !important;
}


.bg-blue {
	background-color: $secondary-blue !important;
	color: $white !important;
}

.bg-blue-light {
	background-color: $secondary-blue-lightest !important;
	color: $white !important;
}

.bg-secondary {
	background-color: $primary-green !important;
	color: $white !important;
}

.bg-secondary-light {
	background-color: $primary-green-lightest !important;
	color: $primary-green !important;
}

.bg-gray-light {
	background-color: $secondary-black-lightest !important;
	color: $primary-blue !important;
}

.bg-sand {
	background-color: $secondary-sand !important;
	color: $primary-blue !important;
}

.bg-gray {
	background-color: $secondary-black-lighter !important;
	color: $white!important;
	a {
		color: $secondary-blue-lightest !important;
	}
}

.bg-yellow {
	background-color: $secondary-yellow !important;
	color: $white !important;
}
.bg-yellow-light {
	background-color: $secondary-yellow-lightest !important;
	color: $secondary-yellow !important;
}

.bg-orange {
	background-color: $secondary-orange !important;
	color: $white !important;
}
.bg-orange-light {
	background-color: $secondary-orange-lightest !important;
	color: $secondary-orange !important;

}
.bg-pink {
	background-color: $secondary-pink !important;
	color: $white!important;
}
.bg-pink-light {
	background-color: $secondary-pink-lightest !important;
	color: $secondary-pink !important;
}

.bg-red {
	background-color: $secondary-red !important;
	color: $white !important;
}

.bg-red-light {
	background-color: $secondary-red-lightest !important;
	color: $secondary-red !important;
}
.bg-purple{
	background-color: $secondary-purple !important;
	color: $white !important;
}
.bg-purple-light {
	background-color: $secondary-purple-lightest !important;
	color: $secondary-purple !important;
}

.bg-turquoise {
	background-color: $secondary-turquoise !important;
	color: $white !important;
}

.bg-turquoise-light {
	background-color: $secondary-turquoise-lightest !important;
	color: $primary-blue !important;
}

.bg-brown {
	background-color: $secondary-brown !important;
	color: $white !important;
}
.bg-brown-light {
	background-color: $secondary-brown-lightest !important;
	color: $secondary-brown !important;
}




.bg-transparent-blue {
	background-color: transparent !important;
	color: $blue !important;
}

.bg-transparent-lightblue {
	background-color: transparent !important;
	color: $secondary-blue !important;
}

.bg-transparent-red {
	background-color: transparent !important;
	color: $red !important;
}

.bg-transparent-purple {
	background-color: transparent !important;
	color: $secondary-purple !important;
}

.bg-transparent-pink {
	background-color: transparent !important;
	color: $secondary-pink !important;
}

.bg-transparent-orange {
	background-color: transparent !important;
	color: $secondary-orange !important;
}

.bg-transparent-yellow {
	background-color: transparent !important;
	color: $secondary-yellow !important;
}

.bg-transparent-white {
	background-color: transparent !important;
	color: $white !important;
}

.bg-transparent-green {
	background-color: transparent !important;
	color: $primary-green !important;
}







