.cta_bg {
	overflow: hidden;
	position: relative;

	&__image {
		width: 100%;
		height: auto;
		position: relative;
		overflow: hidden;

		img {
			width: 100%;
			height: auto;
		}
	}

	&__overlay {
		text-align: center;
		color: $white;
		height: 100%;
		width: auto;

		@media (min-width: 1200px) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-position: center;
			background-repeat: no-repeat;
			background-size: 85%;
		}

		@media (max-width: 1200px) {
			background: none !important;
			position: relative;
			background-color: $gray-light !important;
			height: 100%;
			width: auto;
			padding-top: 4rem;
			padding-bottom: 4rem;
		}



		&__wrap {
			flex-direction: column;
		}

		&__headline {
			@media (min-width: 1400px) {
				margin-bottom: 2rem;
			}

			@media (max-width: 1200px) {
				margin-bottom: 2rem;
			}

			h2 {
				color: $primary-blue;
				line-height: 1em;
				font-size: 3rem;

				@media (max-width: 1200px) {
					font-size: 3rem;
				}

				@media (min-width: 1900px) {
					font-size: 5rem;
				}
			}

			p {
				color: $primary-blue;
				font-size: 16px;
				line-height: 1em;
			}
		}

		&__text {
			max-width: 70%;

			@media (max-width: 1023px) {
				max-width: 100%;
			}

			@media (min-width: 1200px) {
				max-width: 90%;
			}

			@include media-breakpoint-down(md) {
				font-size: 2rem;
			}

			@media (min-width: 1400px) {
				margin-bottom: 2rem;
			}

			@media (min-width: 1900px) {
				margin-bottom: 4rem;
			}

			p {
				color: $primary-blue;
			}
		}

		&__btn_wrap {


			@media (max-width: 375px) {
				display: inline-block;
			}

			&__btn {
				font-size: 14px;
				padding: 1rem 2rem !important;
				font-weight: 600;
				text-transform: uppercase;
				background-color: $primary-blue;
				color: $white;
				display: inline;
				min-width: 17rem;
				margin: 1rem;
				border-radius: 2.2rem;
				border: 1px solid $primary-blue;
				text-decoration: none;
				cursor: pointer;

				@media (max-width: 375px) {
					display: block;
					width: 100%;
				}

				@media (min-width: 1024px) and (max-width:1400px) {
					font-size: 12px;
				}

				&:hover {
					background-color: $white;
					color: $primary-blue;
					border: .5px solid $primary-blue;
				}
			}
		}
	}
}
