.anchor-targeting {
	h2 {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}
	}

	.anchor-to-top {
		i {
			font-size: 3rem;
		}
	}
}
