.iconbox {
	padding: 4rem 0;
	overflow: hidden;

	@include media-breakpoint-up(lg) {
		padding: 6rem 0;
	}

	&__headline {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-weight: 600;
		font-size: 3rem;
		margin-bottom: 2rem;

		@include media-breakpoint-up(lg) {
			font-size: 2.6rem;
		}
	}

	&__text {
		@include themify() {
			color: getThemifyVariable('color-text');
		}
	}

	&__primary {
		&__button {
			@include theme-button-color;
			margin-right: 1rem;
		}
	}

	&__secondary {
		&__button {
			@include theme-button-color;
		}
	}

	&__content {
		z-index:99;
	}

	&__wrap {
		@include media-breakpoint-down(md) {
			width: 100%;
		}

		.btn {
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}

	.offside-img {
		position: absolute;
		right: -20px;
		top: -80px;
		z-index: 0;

		img {
			min-width: 400px;
			height:auto;
		}
	}


	img {
		@media (max-width: 768px) {
			width: 90%;
		}

		@include media-breakpoint-up(lg) {
			padding: 1rem;
		}
	}
}
