@mixin theme-color-headline() {
	color: $primary-blue !important;

	.bg-primary & {
		color: $white !important;
	}

	.bg-primary-light & {
		color: $primary-blue !important;
	}

	.bg-secondary & {
		color: $secondary-green-lightest !important;
	}

	.bg-secondary-light & {
		color: $primary-green !important;
	}

	.bg-gray & {
		color: $secondary-blue-lightest !important;
	}

	.bg-gray-light & {
		color: $primary-blue !important;
	}
}

@mixin theme-color-offset() {

	.bg-primary {
		color: $primary-green !important;
	}

	.bg-primary-light {
		color: $primary-green !important;
	}

	.bg-secondary {
		color: $primary-blue !important;
	}

	.bg-secondary-light {
		color: $primary-blue !important;
	}

	.bg-gray {
		color: $secondary-blue-lightest !important;
	}

	.bg-gray-light {
		color: $secondary-gray !important;
	}


	.bg-transparent-blue {
		color: $primary-blue-lightest !important;
	}

	.bg-transparent-lightblue {
		color: $secondary-blue-lightest !important;
	}

	.bg-transparent-red {
		color: $secondary-red-lightest !important;
	}

	.bg-transparent-purple {
		color: $secondary-purple-lightest !important;
	}

	.bg-transparent-pink {
		color: $secondary-pink-lightest !important;
	}

	.bg-transparent-orange {
		color: $secondary-orange-lightest !important;
	}

	.bg-transparent-yellow {
		color: $secondary-yellow-lightest !important;
	}

	.bg-transparent-green {
		color: $primary-green !important;
	}
}

@mixin theme-color-text() {
	.bg-primary & {
		color: $white !important;
	}

	.bg-primary-light & {
		color: $secondary-black !important;
	}

	.bg-secondary & {
		color: $white !important;
	}

	.bg-red & {
		color: $white !important;
	}

	.bg-secondary-light & {
		color: $secondary-black !important;
	}

	.bg-gray & {
		color: $secondary-blue-lightest !important;
	}

	.bg-gray-light & {
		color: $secondary-blue-lightest !important;
	}


	.bg-white-lightblue & {
		color: $secondary-blue-lightest !important;
	}

	.bg-transparent-blue & {
		color: $secondary-blue !important;
	}

	& .bg-transparent-lightblue {
		color: $secondary-blue-lightest !important;
	}



	.bg-transparent-red & {
		color: $secondary-red !important;
	}

	.bg-transparent-purple & {
		color: $secondary-purple !important;
	}

	.bg-transparent-pink & {
		color: $secondary-pink !important;
	}

	.bg-transparent-orange & {
		color: $secondary-orange !important;
	}

	.bg-transparent-yellow & {
		color: $secondary-yellow !important;
	}

	.bg-transparent-green & {
		color: $primary-green !important;
	}
}


@mixin theme-button-color() {
	&.bg-primary {
		background-color: $primary-blue;
		color: $white;

		&:hover {
			background-color: $primary-green;
			color: $white;
		}
	}

	&.bg-white {
		background-color: $white;
		color: $primary-blue;
		border: 0.5px solid $primary-blue;

		&:hover {
			background-color: $primary-blue;
			color: $white;
			border: 0.5px solid $white;
		}
	}

	.bg-primary-light & {
		background-color: $primary-green;
		color: $white;
	}

	.bg-secondary & {
		background-color: $primary-blue;
		color: $white !important;
	}

	.bg-secondary-light & {
		background-color: $primary-blue;
		color: $white !important;
	}

	.bg-gray & {
		background-color: $secondary-blue-lightest;
		color: $primary-blue;
	}

	.bg-gray-light & {
		background-color: $primary-blue;
		color: $white;
	}
}

/*@mixin theme-color-text() {
	color: $secondary-black-lightest !important;

	.bg-primary & {
		color: $white !important;
	}

	.bg-primary-light & {
		color: $secondary-black !important;
	}

	.bg-secondary & {
		color: $white !important;
	}

	.bg-red & {
		color: $white !important;
	}

	.bg-secondary-light & {
		color: $secondary-black !important;
	}

	.bg-gray & {
		color: $secondary-blue-lightest !important;
	}

	.bg-gray-light & {
		color: $secondary-blue-lightest !important;
	}


	.bg-white-lightblue & {
		color: $secondary-blue-lightest !important;
	}
}*/

@mixin theme-border-color() {
	border-color: $primary-blue !important;

	.bg-primary & {
		border-color: $white !important;
	}

	.bg-primary-light & {
		border-color: $primary-blue !important;
	}

	.bg-secondary & {
		border-color: $secondary-green-lightest !important;
	}

	.bg-secondary-light & {
		border-color: $primary-green !important;
	}

	.bg-gray & {
		border-color: $secondary-blue-lightest !important;
	}

	.bg-gray-light & {
		border-color: $primary-blue !important;
	}

	.bg-red & {
		border-color: $white !important;
	}
}

@mixin theme-color-arrow-right() {
	&:before {
		border-width: 13px;
		margin-top: -13px;
		margin-left: auto;
	}

	&:after {
		border-width: 10px;
		margin-top: -10px;
		margin-left: auto;
	}

	&:before {
		border-top-color: transparent;
		border-right-color: transparent;
		border-bottom-color: transparent;
		border-left-color: $white;
	}

	&:after {
		border-top-color: transparent;
		border-right-color: transparent;
		border-bottom-color: transparent;
		border-left-color: $primary-blue;
	}

	.bg-primary & {
		&:before {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $white;
		}

		&:after {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $primary-blue;
		}
	}

	.bg-primary-light & {
		&:before {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $white;
		}

		&:after {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $secondary-blue-lightest;
		}
	}

	.bg-secondary & {
		&:before {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $white;
		}

		&:after {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $primary-green;
		}
	}

	.bg-secondary-light & {
		&:before {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $white;
		}

		&:after {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $secondary-green-lightest;
		}
	}

	.bg-gray & {
		&:before {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $white;
		}

		&:after {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $secondary-black-light;
		}
	}

	.bg-gray-light & {
		&:before {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $white;
		}

		&:after {
			border-top-color: transparent;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: $secondary-black-lightest;
		}
	}
}

@mixin theme-color-arrow-down() {
	&:before {
		border-width: 13px;
		margin-left: -13px;
	}

	&:after {
		border-width: 10px;
		margin-left: -10px;
	}

	&:before {
		border-top-color: $white;
		border-right-color: transparent;
		border-bottom-color: transparent;
		border-left-color: transparent;
	}

	&:after {
		border-top-color: $primary-blue;
		border-right-color: transparent;
		border-bottom-color: transparent;
		border-left-color: transparent;
	}

	.bg-primary & {
		&:before {
			border-top-color: $white;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}

		&:after {
			border-top-color: $primary-blue;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}
	}

	.bg-primary-light & {
		&:before {
			border-top-color: $white;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}

		&:after {
			border-top-color: $secondary-blue-lightest;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}
	}

	.bg-secondary & {
		&:before {
			border-top-color: $white;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}

		&:after {
			border-top-color: $primary-green;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}
	}

	.bg-secondary-light & {
		&:before {
			border-top-color: $white;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}

		&:after {
			border-top-color: $primary-green-lightest;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}
	}

	.bg-gray & {
		&:before {
			border-top-color: $white;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}

		&:after {
			border-top-color: $secondary-black-light;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}
	}

	.bg-gray-light & {
		&:before {
			border-top-color: $white;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}

		&:after {
			border-top-color: $secondary-blue-lightest;
			border-right-color: transparent;
			border-bottom-color: transparent;
			border-left-color: transparent;
		}
	}
}
