//OVERWRITE BOOTSTRAP DEFAULTS
$font-color-h1:  $primary-blue;
$font-color-h2: $secondary-black-light;
$font-color-p: $font-color-p;
$link-color: $primary-green;
$link-hover-color: $primary-blue;
$btn-border-radius: $btn-border-radius!default;
$abstract-color: $secondary-black-light;

h1 {
	color: $font-color-h1;
	font-size: $h1-font-size;
	font-weight: 300;
}

h2 {
	color: $font-color-h2;
	font-size: $h2-font-size;
	font-weight: 300;
	line-height:1.375;
}

h3 {
	font-weight: 500;
	font-size: $h3-font-size;
	line-height: 1.375;
}

p {
	color: $font-color-p;
	font-weight: 300;
	font-size: 16px;
}

main {
	padding-top: 7.5rem;

	@media (min-width: 1200px) {
		padding-top: 10.8rem;
	}
}

article {
	p {
		font-size: 16px;
		line-height: 24px;
	}

	a {
		color: $link-color;
		text-decoration: none;
		font-weight: 500;

		&:hover {
			color: $link-hover-color;
		}
	}

	li {
		color: $font-color-p;
		font-weight: 300;
		font-size: 16px;
	}

	.page-content {
		line-height: 24px;

		h2 {
			font-weight: 300;
			color: $abstract-color;

			@include media-breakpoint-down(md) {
				font-size: 2rem;
			}
		}
	}
}

.btn {
	border-radius: $btn-border-radius;

	&--pill {
		border-radius: $btn-border-radius;
	}

	&--green {
		background-color: $primary-green;
		color: $white !important;

		&:hover {
			background-color: $blue !important;
		}
	}

	&--blue {
		background-color: $blue !important;
		color: $white !important;

		&:hover {
			background-color: $primary-green !important;
		}
	}
}



