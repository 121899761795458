$h1-font-size: $h1-font-size;

.linkelement {
	text-align: center;

	&__content {
		height: 100%;
		padding: 20px 20px 10px;
		overflow-wrap: break-word;


		@media (max-width: 992px) {
			margin: 1rem;
		}
	}
	&__headline {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-weight: 300;
		font-size: $h1-font-size;
	}

	h2 {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}
	}

	&__text {
		@include themify() {
			color: getThemifyVariable('color-text');
		}

		line-height: 24px;
	}

	&__link {
		text-transform: uppercase;

		a & {
			@include themify() {
				color: getThemifyVariable('color-headline');
			}
		}
	}
}
