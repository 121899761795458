$h1-font-size: $h1-font-size;
$blue: $primary-blue;
$green: $primary-green;
.teasers-ctabox {
	$parent: &;
	height: 100%;
	padding: map-get($spacers, 5) 0;

	&--with-image {
		padding: 0;
	}

	text-align: center;

	&__headline {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-weight: 300;
		font-size: $h1-font-size;

		@include media-breakpoint-down(lg) {
			font-size: $h2-font-size;
		}
	}

	&__image {
		width: 100%;
		height: auto;
	}

	&__block {
		margin: auto;

		#{$parent}--with-image & {
			text-align: left;
			max-width: map-get($container-max-widths, 'sm')
		}
	}

	&__text {
		@include themify() {
			color: getThemifyVariable('color-text');
		}

		line-height: 24px;
	}

	&__button {
		background-color: $green;
		color: #ffffff;

		@include themify() {
			background-color: getThemifyVariable('color-button-bg');
			color: getThemifyVariable('color-button-text');
		}

		&:hover {
			background-color: $blue;
			color: #ffffff;

			@include themify() {
				background-color: getThemifyVariable('color-button-bg-hover');
				color: getThemifyVariable('color-button-text');
			}
		}
	}
}

.tpi-website {
	.bg-white {
		.teasers-ctabox {
			&__button {
				background-color: $blue !important;
				color: $white !important;
				border-color: $blue !important;

				&:hover {
					background-color: $white !important;
					color: $blue !important;
					border-color: $blue !important;
				}
			}
		}
	}
}

