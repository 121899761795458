$hover-color: $primary-green;
$desktop-menu-background: $white-beige;
$box-shadow: $secondary-black-lightere;
$nav-link-1: $primary-blue;
$header-background: $white-beige;
$border-color: $secondary-black-lightest;
$border-color-secondary: $secondary-blue-lightest;
$border-color-dashed: $white;
$tertiary-item-color: $secondary-blue-dark;
$secondary-item-color: $secondary-blue-dark;
$secondary-navbar-color: $white;
$mobile-background-2: $white;
$mobile-background-1: $white-beige;
$mobile-icon-color: $secondary-black-lightere;
$icon-color: $secondary-blue-dark;
$service-text-color: $secondary-black-lighter;
$service-border: $secondary-blue-lightest;
$service-background: $secondary-black-lightest;
$service-background-highlight: $white-dark;
$service-background-last: $white-beige;
$service-search-border-color: $secondary-black-lighter;
$primary-navbar-top-padding: 0 !default;
$primary-navbar-bottom-padding: 0 !default;
$primary-navbar-shrink-bg: $secondary-blue;
$service-nav-bg-color: $secondary-blue-pale;
#mainNav {

	.logo-container {
		z-index: 1200;
	}

	.navbar {
		padding: 0 !important;

		&.primary-navbar {
			padding-top: $primary-navbar-top-padding;
			padding-bottom: $primary-navbar-bottom-padding;
			transition: background-color 0.4s ease;
			z-index: $zindex-fixed;
		}

		&.secondary-navbar {
			display: none;
			z-index: $zindex-fixed;
			color: $secondary-navbar-color;

			@include media-breakpoint-up(xl) {
				position: fixed;
				width: 100%;
				top: 7.7rem;
				left: -42%;
			}

			@include media-breakpoint-down(lg) {
				margin-left: -15px;
			}
		}

		&.tertiary-navbar {
			@include media-breakpoint-up(xl) {
				display: block !important;
			}
		}

		&-collapse {
			flex-direction: column !important;
			align-self: flex-end;
		}

		.container {
			padding: 0;

			@include media-breakpoint-up(md) {
				max-width: 100%;
			}

			align-content: stretch;
		}

		.collapse {
			width: 90%;
		}
	}

	.header-container {
		@include media-breakpoint-down(lg) {
			background-color: $header-background;
		}
	}

	.navbar-controls {
		display: flex;
		flex-direction: row-reverse;
	}

	.navbar-brand {
		padding-top: 0;
		padding-bottom: 0;

		img {
			max-width: 140px;
			width: 140px;
			height: auto;
			margin: 20px;

			@include media-breakpoint-up(xl) {
				margin: 29px;
			}
		}
	}

	.navbar-collapse {
		@include media-breakpoint-up(xl) {
			position: absolute;
			right: 20px;
		}

		&_tertiary {
			@include media-breakpoint-up(xl) {
				position: relative;
				width: 100%;
			}
		}
	}

	.navbar-nav {
		li {
			@include media-breakpoint-down(lg) {
				border-bottom: 1px solid $border-color;
				border-right: 1px solid $border-color;
			}
		}


		&__main {

			@include media-breakpoint-down(lg) {
				-webkit-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
				-moz-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
				box-shadow: 0px 4px 2px -2px rgba($box-shadow, .5);
				overflow-y: auto;
				position: absolute;
				height: calc(100vh - 68px);
				width: 50%;
				box-sizing: content-box;
				z-index: 500;
			}

			@include media-breakpoint-down(md) {
				-webkit-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
				-moz-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
				box-shadow: 0px 4px 2px -2px rgba($box-shadow, .5);
				overflow-y: auto;
				position: absolute;
				width: 100%;
				box-sizing: content-box;
				z-index: 500;
			}
		}

		&_secondary {
			@include media-breakpoint-up(xl) {
				width: 100vw;
				left: -58vw;
				position: absolute;
				background-color: $desktop-menu-background;
				padding-left: 20vw;
				min-height: 20rem;
			}

			&_desktop {
				top: -40px;
			}
		}

		&_tertiary {
			@include media-breakpoint-up(xl) {
				flex-direction: column !important;
				width: 100%;
			}
		}

		&_desktop {
			position: absolute;
			right: 0;
			top: -42px;
			height: 20px;
			text-align: right;
			height: auto;

			@include media-breakpoint-up(xl) {
				display: block;
			}

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
	}

	.nav-item {
		z-index: 500;

		@include media-breakpoint-down(lg) {
			background-color: $mobile-background-2;
		}

		@include media-breakpoint-up(xl) {
			padding: 12px 14px 8px 14px;
			border-bottom: 0;
		}

		@include media-breakpoint-down(xl) {
			padding-left: 1rem;
		}

		&_main {
			.fa-angle-right {
				color: #a7a7b1 !important;
			}

			&--isActive {
				.nav-item__row-container_main .nav-item__link-container_main a {
					color: $hover-color !important;
				}

				.nav-item__icon-container_main {
					i:first-child {
						color: $hover-color !important;
						transform: rotate(-180deg);
					}
				}
			}

			&--isHovering {
				position: relative;

				&:after {
					position: absolute;
					width: 0;
					height: 0;
					bottom: 0;
					border-left: 20px solid transparent;
					border-right: 20px solid transparent;
					border-bottom: 10px solid $desktop-menu-background;
					-moz-transform: scale(0.999);
					-webkit-backface-visibility: hidden;
					left: calc(50% - 20px);
				}

				&.nav-item_desktop-service {
					&:after {
						border-bottom: 8px solid $desktop-menu-background;
					}
				}
			}
		}

		&_secondary {
			min-width: 200px;
			flex-direction: row;
			display: block;
			text-transform: uppercase;

			@include media-breakpoint-up(xl) {
				text-align: left;
			}

			&--isActive {
				.nav-item__row-container_secondary .nav-item__link-container_secondary a {
					color: $hover-color !important;
				}

				.nav-item__icon-container_secondary {

					i:first-child {
						color: $hover-color !important;
						transform: rotate(-180deg);
					}
				}
			}
		}

		&_tertiary {
			background-color: $mobile-background-1;
			padding-left: 60px;


			&:hover, &:focus {
				a {
					@include media-breakpoint-up(xl) {
						color: $hover-color !important;
					}
				}
			}

			a {
				color: $tertiary-item-color;
				text-transform: none;


				@include media-breakpoint-up(xl) {
					font-size: 13px;
					font-weight: 400;
					line-height: 14px;
					margin-bottom: 5px;
					letter-spacing: 0.5px;
				}
			}

			@include media-breakpoint-up(xl) {
				background: none;
				padding: 0;
				width: 100%;
			}
		}

		&_desktop-service {
			display: inline-block;
			padding: 3px 5px 0 10px;
			margin-right: -5px;



			&__shop {
				background-color: $service-background-last !important;

				a {
					font-weight: 600;
					line-height: 38px;
				}
			}

			&__language-selector {
				background-color: $service-background-highlight !important;
				z-index: auto;

				a {
					font-weight: 600;
					line-height: 38px;
				}
			}

			&__search {
				background-color: $service-nav-bg-color;
				padding-bottom: 6px;

				a {
					font-weight: 600;
					line-height: 38px;
				}
			}
		}

		&__row-container {
			cursor: pointer;
			display: flex;
			width: 100%;
		}

		&__link-container {
			width: calc(100% - 60px);


			@include media-breakpoint-down(xl) {
				width: 100%;
			}

			@include media-breakpoint-up(xl) {
				padding-left: 0;
			}

			&_main {

				&--isHovering {

					.nav-link_main {
						color: $hover-color !important;
					}
				}
			}

			&_secondary {
				background-color: $mobile-background-1;
				padding-left: 40px;

				a {
					color: $secondary-item-color !important;

					@include media-breakpoint-up(xl) {
						font-weight: 600;
						font-size: 13px;
						line-height: 14px;
						margin-bottom: 10px;

						&:hover, &:focus {
							color: $hover-color !important;
							text-decoration: underline;
						}
					}
				}

				@include media-breakpoint-up(xl) {
					padding-left: 0;
					background: none;
				}
			}

			@include media-breakpoint-up(xl) {
				width: 100%;
			}
		}

		&__icon-container {
			width: 60px;
			text-align: center;
			font-size: 35px;

			i {
				color: $icon-color;
				line-height: 60px;
				-moz-transition: all 0.5s ease;
				-webkit-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				transition: all 0.5s ease;

				@include media-breakpoint-up(xl) {
					display: none;
				}
			}

			&_main {
				background-color: $desktop-menu-background;

				@include media-breakpoint-down(lg) {
					background-color: $mobile-background-2;
				}
			}

			&_secondary {
				background-color: $desktop-menu-background;

				@include media-breakpoint-down(lg) {
					background-color: $mobile-background-1;
				}
			}

			@include media-breakpoint-up(xl) {
				display: none;
			}
		}

		&--isService {
			order: 1;

			@include media-breakpoint-up(xl) {
				display: none;
			}

			@include media-breakpoint-down(lg) {
				background-color: $mobile-background-2;
				color: $service-text-color !important;
			}

			a {
				color: $service-text-color !important;
				font-weight: 400;
			}

			.nav-item__icon-container_main {
				background: none;
			}

			.nav-item_secondary {
			}
		}

		&--isActive {
			color: $hover-color;
		}

		&_search-btn {
			border: none; // 1px solid $service-search-border-color;
			border-right: none;
			background-color: $service-nav-bg-color;
			color: $service-text-color;
			padding: 4px;
			cursor: pointer;
			line-height: normal;

			.fa-search:before {
				font-size: smaller;
			}
		}

		&_search-textbox {
			border: none; // 1px solid $service-search-border-color;
			border-left: none;
			background-color: $service-nav-bg-color;
			padding: 4px;
			text-decoration: none;
			line-height: normal;
			width: 120px;
			font-size: 12px;

			&:focus {
				outline: none;
			}
		}
	}

	::placeholder {
		text-transform: uppercase;
		font-weight: 600;
		color: $service-text-color;
		opacity: 1;
	}

	:-ms-input-placeholder {
		text-transform: uppercase;
		font-weight: 600;
		color: $service-text-color;
	}

	::-ms-input-placeholder {
		text-transform: uppercase;
		font-weight: 600;
		color: $service-text-color;
	}

	.nav-link {
		padding: 16px 80px 16px 25px;
		display: block;
		font-size: 13px;
		font-weight: 600;
		line-height: 16px;
		text-transform: uppercase;


		@include media-breakpoint-up(xl) {
			color: $secondary;
			padding: 0;
		}

		&_main {
			color: $nav-link-1 !important;
		}

		&_desktop-service {
			color: $service-text-color !important;
			font-size: 12px;
			font-weight: 400;
			line-height: 30px;
			letter-spacing: 0.5px;
		}
	}
}


.language-selector {
		background-color: $desktop-menu-background;


		a {
			color: $service-text-color !important;
			font-size: 12px;
			text-transform: uppercase;
		}

		a:hover {
			color: $hover-color !important;
			background-color: $desktop-menu-background;
		}
	}

.service-nav-block {
	display:none;
	@include media-breakpoint-down(lg) {
		display: block;
		margin: 0;
		padding: 30px 15px 15px;
		background-color: $mobile-background-1;
		color: #777;
		border-bottom: 1px solid $border-color;
		text-align: left;
		font-size: 120%;
	}
	}
//Mobile icons
.navbar-icon {
	@include media-breakpoint-up(xl) {
		display: none;
	}

	@include media-breakpoint-down(lg) {
		flex-direction: row;
		position: absolute;
		top: 15px;
		right: 20px;
		z-index: 1200;
	}

	@include media-breakpoint-down(xs) {
		font-size: 20px;
		top: 25px;
		right: 12px;
	}

	li {
		border: none !important;
	}


	.dropdown-toggle::after {
		display: none;
	}

	.nav-item {
		//background-color: $white;
		border: 0;
	}

	.nav-link {
		padding: 0;
		font-size: 32px;
		color: $mobile-icon-color;

		@include media-breakpoint-down(xs) {
			font-size: 20px;
		}
	}

	.dropdown-menu {
		position: absolute;
		right: 0;
		left: auto;
		border: none;
		
	}

	.dropdown-item {
		font-size: 13;
		font-weight: 600;
		text-transform: uppercase;
	}
}

.static-nav {
	@include media-breakpoint-up(xl) {
		margin-right: 2rem;
		padding: 0px 10px 0 10px;
	}
	
		.dropdown-menu {
			width: 100%;
		}

		.language-selector {
			border: none;

			@include media-breakpoint-down(lg) {
				background-color: $header-background;
			}
		}
}
