
.infographicss {
	height: 60%;
	flex: none;
	background-color: rgba(0, 18, 51, 0.00) !important;

	h3 {
		color: $primary-green;
		font-size: 48px;
		line-height: 19px;

		span {
			color: $primary-green;
			word-break: break-word;
			font-size: 24px;
			
		}
	}
}

.infograph_card {
	.card-body {
		p {
			font-size: 80% !important;
			font-weight: 400 !important;
			color: $secondary-black-lightere;
			line-height: initial;
		}
	}
}


.teasers-infographic {
	padding: 20px 0;

	&__headline {
		text-align: center;
		font-size: 2rem;
		color: $primary-blue;
	}

	&__list {
		padding: 0;
		margin: 20px -10px 0 -10px;
		@include clearfix();
	}

	&__item {
		list-style: none;
		float: left;
		margin-bottom: 10px;

		&:nth-child(3n+1) {
			clear: left;
		}

		&:before {
			display: none;
		}

		&__headline {
			margin: 0;
			color: $primary-green;
			font-size: 4rem;
			line-height: 30px;
			text-align: center;
			word-break: break-word;

			&__textfit {
				font-size: 1px;
				opacity: 0;

				&.is-shown {
					opacity: 1;
				}
			}
		}

		span.procent {
			font-size: 32px;
		}

		&__line {
			margin: 1rem;
		}

		&__text {
			font-size: 10px;
			line-height: 12px;
			margin: 0;
			text-align: center;

			p {
				margin: 0;
				font-size: 13px;
				line-height: 1.6;
			}
		}
	}
}

@media only screen and (max-width: md) {
	.teasers-infographic {
		&__item {
			width: 50%;

			&:nth-child(3n+1) {
				clear: none;
			}

			&:nth-child(2n+1) {
				clear: left;
			}

			&__headline {
				font-size: 48px;
				padding-top: 20px;

				&__textfit {
					padding-top: 0;
					font-size: 1px;
				}
			}

			span {
				&.procent {
					font-size: 20px;
					line-height: 25px;
				}
			}
		}
	}
}


@include media-breakpoint-down(sm) {
	.teasers-infographic {
		&__item {
			&__headline {
				font-size: 50px;
			}
		}
	}
}
