$link-font-color: $primary-blue;
.videoMoa {
	position: relative;
	min-height: 600px;
	padding: 2rem;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

	@include media-breakpoint-up(lg) {
		padding: 3rem;
		min-height: 450px;
	}

	&-Headline {
		padding: 1rem;
		margin: 0;

		@include media-breakpoint-up(md) {
			padding: 2rem;
		}

		h2 {
			text-align: center;
			font-weight: 700;
			line-height: 1;
			color: $link-font-color;
			font-size: 2.5rem;

			@include media-breakpoint-up(md) {
				font-size: 3.5rem;
			}
		}
	}

	.img-fluid {
		max-height: 500px;
		margin: 1rem 0;
	}

	.moa-bg {
		position: relative;
		opacity: 100%;

		@include media-breakpoint-down(sm) {
			display: none;
		}

		@include media-breakpoint-up(md) {
			opacity: 30%;
		}

		@include media-breakpoint-up(xl) {
			opacity: 100%;
		}
	}

	.element-2, .element-4 {
		flex-direction: row;

		img {
			float: none;

			@include media-breakpoint-up(md) {
				float: right;
			}
		}
	}

	.element-1,
	.element-3,
	.element-5 {
		flex-direction: row;

		@include media-breakpoint-up(md) {
			flex-direction: row-reverse;
		}
	}

	.element-1,
	.element-2,
	.element-3,
	.element-4,
	.element-5 {
		position: absolute;
		display: flex;
		align-items: center;


		@include media-breakpoint-up(md) {
		}

		img {
			max-width: 80px;
			height: auto;

			@include media-breakpoint-only(md) {
				max-width: 90px;
			}

			@include media-breakpoint-up(lg) {
				max-width: 150px;				
			}
		}

		p {
			word-break: break-word;
			font-weight: bold;
			color: $link-font-color;
			font-size: 2rem;
			margin: 0;
			line-height: 1;
		}
	}


	.element-1 {
		top: 18%;
		left: 5%;

		@include media-breakpoint-up(md) {
			top: 25%;
			left: 0;
		}

		&-link {
			position: absolute;
			top: 26%;
			left: 30%;
			text-transform: uppercase;
			font-weight: bold;

			@include media-breakpoint-only(md) {
				top: 35%;
				left: 8%;
			}

			@include media-breakpoint-only(lg) {
				top: 38%;
				left: 13%;
			}

			@include media-breakpoint-up(xl) {
				top: 38%;
				left: 14%;
			}
		}
	}

	.element-2 {
		top: 34%;
		left: 5%;

		@include media-breakpoint-up(md) {
			top: 32%;
			left: 65%;
		}

		&-link {
			position: absolute;
			top: 42%;
			left: 30%;
			text-transform: uppercase;
			font-weight: bold;

			@include media-breakpoint-only(md) {
				top: 42%;
				left: 73.5%;
			}

			@include media-breakpoint-up(lg) {
				top: 45%;
				left: 73.5%;
			}
		}
	}

	.element-3 {
		top: 50%;
		left: 5%;

		@include media-breakpoint-up(md) {
			top: 48%;
			left: 0;
		}

		&-link {
			position: absolute;
			top: 58%;
			left: 30%;
			text-transform: uppercase;
			font-weight: bold;

			@include media-breakpoint-only(md) {
				top: 57%;
				left: 8%;
			}

			@include media-breakpoint-only(lg) {
				top: 63%;
				left: 13%;
			}

			@include media-breakpoint-up(xl) {
				top: 61%;
				left: 14%;
			}
		}
	}

	.element-4 {
		top: 66%;
		left: 5%;

		@include media-breakpoint-up(md) {
			left: 65%;
			top: 60%;
		}

		&-link {
			position: absolute;
			left: 30%;
			top: 74%;
			text-transform: uppercase;
			font-weight: bold;

			@include media-breakpoint-only(md) {
				left: 73.5%;
				top: 70%;
			}

			@include media-breakpoint-up(lg) {
				left: 73.5%;
				top: 73%;
			}
		}
	}

	.element-5 {
		top: 82%;
		left: 5%;

		@include media-breakpoint-up(md) {
			left: 0;
			top: 72%;
		}

		&-link {
			position: absolute;
			left: 30%;
			top: 89%;
			text-transform: uppercase;
			font-weight: bold;

			@include media-breakpoint-only(md) {
				left: 8%;
				top: 83%;
			}

			@include media-breakpoint-only(lg) {
				left: 13%;
			}

			@include media-breakpoint-up(xl) {
				left: 14%;
				top: 85%;
			}
		}
	}

	.video-btn {
		all: unset;
		cursor: pointer;
	}

	.modal-content {
		border-radius: 1.3rem !important
	}
}
