@import "bootstrap/scss/functions";

@mixin aspect-ratio($width, $height) {
	position: relative;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}

	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@mixin bullet-infront {
	display: list-item;
	list-style-type: disc;
	list-style-position: inside;
}

@mixin bullet-before {
	&:before {
		@include bullet-infront;
	}
}

.aspect-ratio-16-9 {
	@include aspect-ratio(16, 9);
}

.aspect-ratio-4-3 {
	@include aspect-ratio(4, 3);
}

@mixin playicon() {
	display: flex;
	align-items: center;
	justify-content: center;
	content: '\e60d';
	font-family: 'icomoon';
	color: #d4d4d4;
	padding: 5px;
	position: absolute;
	border-radius: 4px;
	top: 40%;
	left: 50%;
	background: $secondary-blue-opacity;
	transition: all 200ms;
	pointer-events: none;
}

@-webkit-keyframes scale-up-ver-top {
	0% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}

	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
}

@keyframes scale-up-ver-top {
	0% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}

	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
}

@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}



@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-moz-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-o-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-ms-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}

}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}

}
