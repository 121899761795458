
.image-ordered {
	height: 100% !important;
	width: 100%;
	overflow: hidden;
	display:block;
}

.equal{
	.content-highlight{
		height:100%;
	}
}
