.page-actions {
	&.inpage-navbar {
		top: 40vh;

		li {
			padding-top: 1rem;
		}
	}

	.print,
	.meta {
		text-decoration: none;
		color: #243574;
		cursor: pointer;

		label {
			display: none;
		}

		@include media-breakpoint-down(sm) {
			label {
				display: block;
			}
		}

		svg {
			position: relative;		
			fill: $white;
			
		}

		&:hover {
			color: #000;
		}
	}

	.share-this-link {
		cursor: pointer;
	}
}

@media print {
	.page-actions {
		display: none;
	}
}


@include media-breakpoint-down(md) {
	.page-actions {
		display: none;
	}
}
