$gray: $font-color-p;

.videolist {
	&__filter {
		&__form {
			display: inline-flex;

			@include media-breakpoint-down(md) {
				display: inline-block;

				label {
					@include media-breakpoint-down(md) {
						margin-bottom: 10px;
						margin-top: 10px;
					}
				}
			}
		}
	}

	button {		
		text-indent: -9999px;		
		background: none;		
		border: none;
		outline: none;		
	}

	label {
		&.search {
			

			&:after {
				display: block;
				content: '\f002';
				font-family: 'fontawesome-webfont';
				color: $gray;
				position: absolute;
				z-index: 1;
				right: 25px;
				top: 5px;
				font-size: 16px;
			}
		}
	}
}
