$link-font-color: $primary-blue;
$background-yellow: $secondary-yellow-lightest;
.languagefallback {
	$parent: &;

	&__notice {
		margin: 10px 0;
		padding: 15px 23px;
		background-color: $background-yellow;
		color: $link-font-color;
		text-align: center;
	}
}
