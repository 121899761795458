$link-font-color: $primary-blue;
$background-color: $secondary-black-lightest;
.news {
	&__newsletter {
		width: 100%;
		margin-left: 0;
		box-sizing: border-box;
		margin-bottom: 25px;
		display: inline-block;

		&__content {
			width: 100%;
			background-color: $background-color;
			color: #4a4a4a;
			padding: 3rem;

			.header {
				font-weight: 700;
				font-size: 14px;
				margin: 0 0 .5em;
				color: $link-font-color;
			}

			.info {
				font-size: 11px;
				line-height: normal;
			}
		}
	}
}
