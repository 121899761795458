﻿
.iconherospot {
	position: relative;
	overflow: hidden;

	@media (max-width: 768px) {
		margin-top: 1rem;
	}

	@include media-breakpoint-up(lg) {
		min-height: 570px;
	}

	&__headline {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-weight: 600;
		font-size: 4.6rem;

		@media (max-width: 768px) {
			font-size: 3rem;
		}
	}

	&__outline {
		@include themify() {
			color: getThemifyVariable('color-text');
		}

		font-weight: 100;
		font-size: 4rem;

		@media (max-width: 768px) {
			font-size: 2.6rem;
		}
	}

	&__icon {
		height: 100%;
		width: auto;
		position: absolute;

		img {
			overflow-x: hidden;
		}
	}



	&__image {
		text-align: center !important;

		@media (max-width: 768px) {
			margin-bottom: 1rem;
		}

		img {
			width: 100%;
			height: auto;
		}


		@include media-breakpoint-up(lg) {
			text-align: right !important;
		}
	}

	&__link {
		@include themify() {
			color: getThemifyVariable('color-text');
		}
	}
}
