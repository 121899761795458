$gray-light: $secondary-black-lighter;
$gray: $font-color-p;
$primary-blue: $primary-blue;
$primary-green: $primary-green;
.videolist {
	&__list {

		&__item {
			a {
				text-decoration: none !important;
				color: $primary-blue !important;
				font-weight: 300;

				&:hover {
					color: $primary-green !important;
				}
			}

			figure {

				&:after {
					display: flex;
					align-items: center;
					justify-content: center;
					content: '\f04b';
					font-family: 'fontawesome-webfont';
					color: #d4d4d4;
					padding: 5px;
					position: absolute;
					border-radius: 4px;
					top: 15%;
					left: 45%;
					background: rgba(0, 0, 0, 0.5);
					transition: all 200ms;
					pointer-events: none;
					width: 25px;
					height: 25px;

					@include media-breakpoint-down(md) {
						width: 35px;
						height: 35px;
						top: 20%;
					}
				}

				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}

			.content {

				.meta {
					color: $gray-light;
					font-size: 9px;
					font-weight: 600;
					text-transform: uppercase;
					display: block;
					position: relative;
					margin-top: 10px;
					margin-bottom: 10px;
					line-height: 1.75rem;
				}

				.h2 {
					color: $primary-blue;
					font-weight: 300;

					&:hover {
						color: $primary-green;
					}
				}

				.header {
					display: block;
					margin-bottom: 5px;
					height: 42px;
					overflow: hidden;
					font-size: 15px;
					line-height: 20px;
					font-weight: 500;
					width: 100%;

					&h2 {
						color: $primary-blue;
						font-weight: 300;

						&:hover {
							color: $primary-green;
						}
					}
				}

				p {
					font-size: 12px;
					line-height: 17px;
					height: 68px;
					overflow: hidden;
					color: $gray;
				}

				.categories {
					display: none;
				}
			}
		}
	}
}
