﻿$headline-color: $white;
$dark-blue: $secondary-blue-dark;
$secondary-blue-opacity:$secondary-blue-darkest-with-opacity;
.herospot {
	$parent: &;
	max-height: 530px;
	overflow: hidden;

	header {
		/*background-color: rgba(0,18,51,.87);*/
		background-color: $dark-blue;
		opacity: 0.9;
		width: auto;
		max-width: 500px;
		min-width: 350px;
		top: auto;
		left: auto;
		bottom: 0;
		right: 61%;

		@include media-breakpoint-down(md) {
			left: 0;
		}

		padding: 15px 20px 25px;
		position: absolute;
		z-index: 9;

		@include media-breakpoint-down(sm) {
			padding: 15px 20px 15px;
			position: inherit;
			width: 100%;
		}
	}

	&__button {
		@include themify() {
			color: getThemifyVariable('color-button-text');
		}

		&__nolink {
			cursor: default;
		}
	}

	&__img {
		max-width: 100%;
		max-height: 100%;
		width: 100%;
		height: auto;
	}

	&__meta {
		font-size: 10px;
		line-height: 1.8em;
		font-weight: 600;
		text-transform: uppercase;
		color: #b0aeae;
		letter-spacing: 1px;
	}

	&__headline {
		padding: .25em 0;
		color: $headline-color;
		font-size: 1.75em;
		font-weight: 100;

		@include media-breakpoint-down(md) {
			font-size: 16px;
		}
	}

	&__section {
		max-height: 360px;
		overflow: hidden;

		header {
			/*background-color: rgba(0,18,51,.87);*/
			background-color: $dark-blue;
			opacity: 0.9;
			width: 70%;
			height: 40%;
			top: auto;
			left: auto;
			bottom: 0;
			right: 0;
			padding: 15px 20px 25px;
			position: absolute;
			z-index: 9;

			@include media-breakpoint-down(sm) {
				padding: 8px;
			}

			@include media-breakpoint-down(lg) {
				width: 100%;
			}

			@include media-breakpoint-only(lg) {
				padding: 10px;
			}
		}

		&__meta {
			font-size: 10px;
			line-height: 1.8em;
			font-weight: 600;
			text-transform: uppercase;
			color: #b0aeae;
			letter-spacing: 1px;
		}

		&__headline {
			font-weight: 400;
			padding: .2em 0;
			color: #ffffff;
			font-weight: 100;

			@include media-breakpoint-down(lg) {
				font-size: 16px;
				line-height: 20px;
			}

			@include media-breakpoint-down(xs) {
				font-size: 12px;
				line-height: 16px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 14px;
				line-height: 18px;
			}


			@include media-breakpoint-only(md) {
				font-size: 14px;
				line-height: 16px;
			}

			@include media-breakpoint-only(lg) {
				font-size: 16px;
				line-height: 18px;
			}

			@include media-breakpoint-only(xl) {
				font-size: 18px;
				line-height: 20px;
			}
		}

		&__text {
			font-size: 12px;
			color: #b0aeae;
		}
	}

	&__left-align {
		header {
			right: inherit;
			left: 0;
		}
	}

	&__slider {
		&__thumbnail {
			width: 80px;
			height: 80px;
			border: 2px solid #CCCCCC;
			position: absolute;
			bottom: 0;
			padding: 0;
			margin: 0;
			left: 0;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}

.eurolandsection {
	p[data-eurolandkey="Last"] {
		font-size: 15px;
		margin: 0;
		color: $headline-color;
	}

	p[data-eurolandkey="ChangePercent"] {
		margin-top: -5px;
		margin-bottom: 0;
		padding-left: 10px;
		font-size: 12px;
		color: $headline-color;

		&.negative {
			background: url(../images/CH_arrow_down.gif) no-repeat left center;
		}

		&.positive {
			background: url(../images/CH_arrow_up.gif) no-repeat left center;
		}
	}
}

.video_icon {
	&:after {
		@include playicon();
		width: 6rem;
		height: 6rem;
		font-size: 42px;
		line-height: 25px;
		padding: 5px;
		margin: -13px;
	}
}

.heroslider {
	$parent: &;

	&__container {
		ul {
			list-style-type: none;
			padding: 0;

			li {
				position: relative;

				header {
					background-color: $dark-blue;
					opacity: 0.9;
					width: auto;
					max-width: 500px;
					min-width: 300px;
					min-height: 110px;
					top: auto;
					left: auto;
					bottom: 0;
					right: 60%;
					padding: 15px 20px 25px;
					position: absolute;
					z-index: 9;

					@include media-breakpoint-down(md) {
						position: inherit;
						max-width: 100%;
						min-width: 100%;
					}

					@include media-breakpoint-down(sm) {
						padding: 10px;
					}

					@include media-breakpoint-down(lg) {
						width: 100%;
					}

					@include media-breakpoint-only(lg) {
						padding: 10px;
					}
				}
			}
		}
	}
}

.pagemode-edit {
	.heroslider__container {
		&.scEnabledChrome {
			li{
			max-height:680px;
			overflow: hidden;
			}
		
		}
	}

	.herospot {
		&__img {
			&.scEnabledChrome {
				width: 100%;
				height: auto;
				overflow: hidden;
			}
		}
	}
}
