$link-font-color: $primary-blue;
.videomodule {
	h1 {
		font-size: 36px;
		font-weight: 300;
		margin-bottom: 15px;
		clear: both;
		line-height: 1.15em;
		color: $link-font-color;

		@include media-breakpoint-down(md) {
			font-size: 32px;
		}
	}

	p {
		@include media-breakpoint-down(md) {
			font-size: 13px;
		}
	}

	.video-embed {
		display: block;
		position: relative;
		height: 0;
		padding-top: 56.25%;
		margin-bottom: 30px;
		max-width: 100%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
