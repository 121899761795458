
$primary-blue-color: $primary-blue;
$white: $white;
$blue-link-color: $primary-blue;
$dark-blue: $secondary-blue-dark;
$blue-light: $secondary-blue-lightest;
$border-color: $secondary-black-lightest;
$box-shadow: $secondary-black-lightere;
$tertiary-item-color: $secondary-black-light;
$primary-nav-background: $primary-navigation-background-color;
$service-nav-background: $service-navigation-background-color;
$rounded-corners: 0.5rem;

#globalNavigation {
	min-height: 74px;
	background-color: $white;
	-webkit-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
	-moz-box-shadow: 0 4px 2px -2px rgba($box-shadow, .5);
	box-shadow: 0px 4px 2px -2px rgba($box-shadow, .5);

	@include media-breakpoint-up(xl) {
		.mobile {
			display: none;
		}
	}

	@media (max-width: 1199px) {
		#primarynav {
			margin-top: 2rem;
			max-height: 90vh;
			align-items: flex-start;
			overflow-y: auto;
			overflow-x: hidden;
			-ms-overflow-style: -ms-autohiding-scrollbar;
		}
	}

	@media (min-width: 1199px) {
		#primarynav {
			margin-right: 5rem !important;
		}
	}

	.navbar {
		padding-left: 1rem;
		padding-right: 1rem;

		@include media-breakpoint-up(lg) {
			padding-left: 3rem;
			padding-right: 3rem;
		}

		.country-notification-mobile {
			font-size: small;
			font-style: italic;
			position: absolute;
			top: 0px;
			right: 15px;

			@include media-breakpoint-up(xl) {
				display: none;
			}
		}

		.country-notification-desktop {
			font-size: small;
			font-style: italic;

			@media (max-width: 1199px) {
				display: none;
			}
		}

		&-nav {
			.dropdown-menu {
				float: left;
			}
		}

		a {
			text-decoration: none;
		}

		.navbar-brand {
			.desktop-logo {
				height: auto;
				width: 185px;

				@include media-breakpoint-up(sm) {
					top: 8.8rem;
					width: 220px;
				}
			}
		}

		.service-icons {
			display: flex;

			a {
				color: $tertiary-item-color;
			}
		}

		.btn, button {
			border: none;
			background: none;

			&:focus {
				outline: none !important;
				box-shadow: none !important;
			}

			&:active {
				border: none;
				outline: none !important;
				box-shadow: none !important;
			}
		}



		.navbar-toggler {
			color: $blue-link-color;
			z-index: 1002;
			position: relative;
		}

		.service-nav {
			margin-top: -5px;
			font-size: 13px;

			.nav-item {
				&:last-child {
					border-left: none;
				}

				&:first-child {
					border-radius: 0;

					@include media-breakpoint-up(xl) {
						border-radius: 0 0 0 $rounded-corners;
					}
				}

				&.transport-link {
					background-color: $blue-light;
					border-left: 1px solid $border-color;
				}

				.service-link {
					color: $tertiary-item-color;
					position: relative;
					padding: 2rem;

					@include media-breakpoint-up(xl) {
						padding: 5px 15px;
						height: 3rem;
					}

					&:hover {
						color: $primary-blue-color;
					}
				}
			}
		}

		.primary-nav {
			margin-left: auto;
			margin-right: 4rem;
			padding-top: 4rem;

			@include media-breakpoint-down(lg) {
				margin-right: auto;
				padding-top: 0;
			}

			.primary-link {
				color: $blue-link-color;
				font-weight: 600;
				position: relative;

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}

				&:hover {
					color: $primary-blue-color !important;
				}
			}

			.secondary-link {
				padding-top: 1rem;
				color: $dark-blue;
				font-weight: 400;
				padding-right: 5px;
				transition: padding-right .2s ease;

				&:hover {
					color: $primary-blue-color;
					padding-right: 5px;
					transition: padding-right .2s ease;
				}

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}
			}

			.tertiary-link {
				color: $dark-blue;
				font-weight: 200;

				&:hover {
					color: $primary-blue-color;
				}

				@include media-breakpoint-down(sm) {
					font-size: 13px;
				}

				&:hover {
					color: $primary-blue-color !important;
				}
			}

			.sub-nav {
				.dropdown-menu {
					.close {
						color: $blue-link-color;

						span {
							color: $blue-link-color;
						}
					}
				}
			}
		}

		@include media-breakpoint-down(xl) {
			.icon-container {
				i {
					-moz-transition: all 0.5s ease;
					-webkit-transition: all 0.5s ease;
					-o-transition: all 0.5s ease;
					transition: all 0.5s ease;
				}

				.fa {
					font-size: 25px;
				}
			}
		}

		.sub-nav {
			position: static !important;

			@include media-breakpoint-down(lg) {
				padding: 2rem;
				border-bottom: 1px solid $border-color;
			}

			@include media-breakpoint-up(lg) {
				padding: 1rem;
			}

			&.nav-item {
				&.show {
					@include media-breakpoint-up(xl) {
						.primary-link {
							&:before {
								border: solid $white;
								content: " ";
								position: absolute;
								pointer-events: none;
								border-color: transparent;
								border-top-color: $white;
								border-width: 18px;
								z-index: 1048;
								bottom: -50px;
								right: 40%;
							}
						}
					}

					@include media-breakpoint-down(xl) {
						.icon-container {
							i {
								transform: rotate(-180deg);

								&.fa-angle-down {
									transform: rotate(-180deg);

									&:active {
										border: none;
										box-shadow: none;
									}
								}
							}

							.btn {
								&:focus {
									box-shadow: none;
								}

								&:active {
									border: none;
									box-shadow: none;
								}
							}

							&button {
								background-color: $tertiary-item-color;
								border: none;
								color: white;
								padding: 12px 16px;
								font-size: 16px;
								cursor: pointer;
								border-radius: 1px;

								&:active {
									border: none;
									box-shadow: none;
								}
							}
						}
					}
				}
			}

			&.isSelected {
				.primary-link {
					color: $primary-blue-color !important;
				}

				.service-link {
					color: $primary-blue-color !important;
				}
			}

			.close {
				position: absolute;
				right: 35px;
				z-index: inherit;
				top: 15px;

				span {
					font-size: 3rem;
					font-weight: 100;
				}
			}
		}

		.secondary-nav {
			padding: 20px 20px;
			width: 100%;
			margin: 0;

			&.dropdown-menu {
				@include media-breakpoint-down(xl) {
					border: none;
					border-radius: 0;
				}

				&.show {
					@include media-breakpoint-up(xl) {
						border-right: none;
						border-left: none;
					}
				}
			}

			.dropdown-item {
				padding-top: 2rem;
				padding-bottom: 2rem;

				&:active {
					text-decoration: none;
					background-color: transparent;
				}

				&:hover {
					text-decoration: none;
					background-color: transparent;
				}
			}

			.primaryblock {
				background-color: $primary-nav-background;
				color: $white;
				padding: 0;


				@include media-breakpoint-up(xl) {
					padding: 3rem 8rem;
					display: flex;
					justify-content: flex-end;
				}

				ul {
					display: inherit;
					list-style: none;
					padding-left: 1rem;

					li {
						padding-top: 2rem;
						padding-right: 20px;

						&:hover {
							color: $primary-blue-color;
						}

						.tertiary-level {
							a {
								font-weight: 600;
								color: $primary-blue-color;

								&:hover {
									color: $dark-blue;
								}
							}
						}
					}
				}
			}
		}

		.secondary-level {
			list-style: none;
			display: block;
			padding: 2rem;



			@include media-breakpoint-up(xl) {
				display: inline-block;
			}
		}


		.tertiary-level {

			.tertiary-item {
				padding-bottom: 1rem;
			}
		}
	}

	.navbar-icon-dropdown {
		color: $blue-link-color;
		position: absolute;
		top: 21px;
		right: 100px;

		@include media-breakpoint-up(sm) {
			top: 21px;
			right: 125px;
		}

		@include media-breakpoint-up(xl) {
			/*top: -3px;
			right: 170px;*/
			top: 65px;
			right: 70px;
		}

		#globalLanguageDropdown {
			font-size: 36px;
			/*transform: translate3d(-45px, 65px, 0px) !important;*/
			@include media-breakpoint-up(xl) {
				font-size: 33px;
				/*transform: translate3d(-90px, 20px, 10px) !important;*/
				/*transform: translate3d(-14px, 32px, 0px) !important;*/
			}
		}

		.dropdown-menu {
			/*float: left;*/
			display: none;
			z-index: 1003;
			min-width: 16rem;
			font-size: 1.6rem;
			color: $white;
			list-style: none;
			background-color: $dark-blue;
			border-radius: 0;
			top: 6.5rem;

			@include media-breakpoint-up(xl) {
				/*transform: translate3d(-14px, 32px, 0px) !important;*/
				top: 6.4rem;
			}

			.dropdown-item {
				color: $white !important;

				&:hover {
					background-color: $primary-nav-background;
					color: $dark-blue !important;
					font-weight: 300;
				}

				&:first-child, &:last-child {
					border-radius: unset;
				}
			}
		}
	}

	.search-icon {
		position: absolute;
		right: 70px;
		top: 33px;

		@include media-breakpoint-up(xl) {
			top: 75px;
			right: 25px;
		}

		@include media-breakpoint-down(xs) {
			right: 50px;
		}



		.badge {
			padding: 0.6em 0.6em;
			width: 3rem;
			height: 3rem;
		}

		.fa-search {
			&:before {
				font-size: 1.3em;
			}
		}
	}
	/*search*/
	.navbar-search {
		position: absolute;
		top: 13rem;
		right: 0rem;
		width: 35%;

		@include media-breakpoint-only(lg) {
			width: 40%;
		}

		@include media-breakpoint-down(lg) {
			top: 8.8rem;
			width: 100%;
		}

		.fa-search {
			color: $white;
		}

		form {
			display: none;
			background: $dark-blue;
			text-align: center;
			padding: 2rem;
			-webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
			animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
		}

		input {
			border: none;
			background: none;
			color: $white;
			outline: none;
			width: 80%;
			font-size: 18px;
			font-weight: 300;
		}

		button {
			background: none;
			border: none;
		}

		input::-webkit-input-placeholder {
			color: $white;
			font-weight: 300;
		}
	}
	/* ANIMATED Toggler */
	.navbar-toggler {
		&:focus, &:active {
			outline: 0;
		}

		span {
			display: block;
			background-color: $tertiary-item-color;
			height: 3px;
			width: 25px;
			margin-top: 4px;
			margin-bottom: 4px;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			position: relative;
			left: 0;
			opacity: 1;
			transition: all 1s ease;

			&:nth-child(1), &:nth-child(3) {
				-webkit-transition: transform 1s ease;
				-moz-transition: transform 1s ease;
				-o-transition: transform 1s ease;
				transition: transform 1s ease;
			}
		}

		&:not(.collapsed) {
			span {
				&:nth-child(1) {
					position: absolute;
					left: 5px;
					top: 5px;
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
					opacity: 0.8;
				}

				&:nth-child(2) {
					height: 2px;
					opacity: 0;
					transform: translate(150%,-50%);
				}

				&:nth-child(3) {
					position: absolute;
					left: 5px;
					top: 5px;
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
					opacity: 0.8;
				}
			}
		}
	}

	.slide-in-right {
		-webkit-animation: slide-in-right 2s ease-out both;
		animation: slide-in-right 2s ease-out both;
	}
}
