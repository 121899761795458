$gray: $secondary-black-lighter;
.news {

	&__article {

		&__content {
			font-size: 13px;
			position: relative;
			line-height: 24px;
			margin-bottom: 30px;


			figure {
				margin-bottom: 15px;

				img {
					display: block;
					width: 100% !important;
					height: auto !important;
				}
			}

			img {
				width: 100% !important;
				height: auto !important;
			}

			.image-caption, .image-meta {
				line-height: 1.2;
			}

			table {
				width: 100%;
				margin-bottom: 1rem;
				border-spacing: 2px;


				tr {
					display: table-row;
					vertical-align: inherit;
					border-top: 1px solid $gray;
				}

				td {
					@include media-breakpoint-down(md) {
						padding: 0 !important;
						font-size: 11px;
					}

					@include media-breakpoint-down(xs) {
						padding: 0 !important;
						font-size: 9px;
					}
				}
			}

			.attachments {
				border-top: 1px solid #ccc;
				border-bottom: 1px solid #ccc;
				padding: 25px 0;

				h4 {
					color: #002a65;
					font-size: 16px;
					line-height: 1em;
					margin: 0 0 1em;
					font-weight: 300;
				}


				a {
					&.file {
						display: block;
						width: 66px;
						padding-top: 15px;
						height: 58px;
						overflow: hidden;

						&.pdf {
							&:before {
								content: "\e63c";
								color: #da2220;
								display: block;
								font-family: icomoon;
								font-size: 65px;
							}
						}
					}
				}
			}
		}
	}
}
