$buttonBackgroundColorHover: $primary-blue;
$borderColor: $gray-light;
$filterLabelColor: $secondary-black-lighter;
$loadMoreBgColor: $white-dark;

.search {
	&__section {
		padding: 20px;
	}

	.search-for {
	}

	&__field {
		padding: .85em 1.2em;
		margin: 0;
		display: block;
		width: 100%;
		max-width: 500px;
		font-family: inherit;
		text-transform: uppercase;
		font-size: 1em;
		font-weight: 400;
		border: 1px solid #f6f8fa;
		outline: none;
		box-sizing: border-box;
		background-color: #fff;
		box-shadow: inset 1px 1px 5px rgba(0,0,0,.22);
	}

	&__button__field {
		margin-top: 0;
		padding: .85em 1.2em;
		text-transform: uppercase;

		@include media-breakpoint-down(md) {
			margin-top: 1rem;
		}
		/*@include themify() {
			background-color: getThemifyVariable('color-button-bg');
			color: getThemifyVariable('color-button-text');
		}

		&:hover {
			@include themify() {
				background-color: getThemifyVariable('color-button-bg-hover');
				color: getThemifyVariable('color-button-text');
			}
		}*/
	}

	&__filters {
		border-bottom: 1px solid $borderColor;
		padding: 10px 0;

		&__list {
			list-style: none;
			padding: 0;
			margin: 0;

			&__item {
				display: inline-block;

				input[type=checkbox] {
					display: none;

					&:checked {
						+ label {
							@include themify() {
								color: getThemifyVariable('color-button-bg');
							}

							&:before {
								text-indent: 0;

								@include themify() {
									color: getThemifyVariable('color-button-bg');
								}
							}
						}
					}
				}

				label {
					font-weight: 700;
					font-size: .9em;
					text-transform: uppercase;
					padding: .25em 0;
					margin: 10px 15px 0 0;
					line-height: 1.5em;
					cursor: pointer;
					background-position: 0 50%;
					color: $filterLabelColor;
					transition: color .3s ease;

					&:before {
						content: "\f00c";
						margin-right: 10px;
						display: inline-block;
						text-indent: -9999px;
						width: 22px;
						height: 22px;
						text-align: center;
						line-height: 22px;
						vertical-align: middle;
						border-radius: 4px;
						box-shadow: inset 1px 1px 5px rgba(0,0,0,.22);
						font-family: 'fontawesome-webfont';
					}
				}
			}
		}
	}

	&__results {
		&__amount {
			font-size: 1.375em;
			line-height: 1.375em;
			font-weight: 600;
			color: $primary-blue !important;
		}

		&__more {
			margin-top: 10px;
			display: inline-block;
			font-size: 12px;
			line-height: 14px;
			text-align: center;
			text-transform: uppercase;
			cursor: pointer;
			padding: 12px 23px;
			background: $loadMoreBgColor;
			border-style: none;
		}

		&__list {
			padding: 0;
			list-style-type: none;

			&__item {
				padding: 0;
				margin: 0 0 15px;

				&__image {
					width: 100%;
				}

				&__content {

					&__meta {
						margin-bottom: 5px;
						color: $filterLabelColor;
						font-size: 10px;
						line-height: 14px;
						font-weight: 600;
						text-transform: uppercase;
					}

					h2 {
						color: $primary-blue !important;
					}
				}
			}

			a {
				text-decoration: none;
			}
		}
	}

	#search__button__more {
		color: rgb(0, 56, 111);
	}
}
