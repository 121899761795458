$themes: (
    bg-white: (
        color-headline: $primary-blue !important,
        color-offset: $primary-green !important,
        color-text: $secondary-black-light !important,
		color-hover-text: $white  !important,
        color-button-bg: $primary-green !important,
	    color-button-bg-hover: $primary-blue !important,
        color-button-text: $white !important,
        color-border: $primary-blue !important,
        color-arrow: $white !important
    ),

    bg-primary: (
        color-headline: $white !important,
        color-offset: $primary-green !important,
        color-text: $white!important,
		color-hover-text: $white  !important,
        color-button-bg: $primary-green !important,
	    color-button-bg-hover: $secondary-blue !important,
        color-button-text: $white !important,
        color-border: $white !important,
        color-arrow: $primary-blue!important
    ),
	
	bg-primary-dark:(
        color-headline: $white !important,
        color-offset: $white !important,
        color-text: $white !important,
		color-hover-text: $white !important,
        color-button-bg: $primary-green !important,
	    color-button-bg-hover: $primary-blue !important,
        color-button-text: $white !important,
        color-border: $white !important,
        color-arrow: $white !important
    ),

    bg-primary-light: (
        color-headline: $primary-blue !important,
        color-offset: $primary-green !important,
        color-text: $secondary-black-light  !important,
		color-hover-text: $white  !important,
        color-button-bg: $primary-green !important,
	    color-button-bg-hover: $primary-blue !important,
        color-button-text: $white !important,
        color-border: $primary-blue !important,
        color-arrow: $secondary-blue-lightest !important
    ),

    bg-secondary: (
      color-headline: $white !important,
      color-offset: $primary-blue !important,
      color-text: $white !important,
	  color-hover-text: $white  !important,
      color-button-bg: $primary-blue !important,
	  color-button-bg-hover: $primary-green-lightest !important,
      color-button-text: $white !important,
      color-border: $white !important,
      color-arrow: $primary-green !important
    ),

    bg-secondary-light: (
      color-headline: $primary-green !important,
      color-offset: $primary-blue !important,
      color-text: $secondary-black-light !important,
	  color-hover-text: $white  !important,
      color-button-bg: $primary-green !important,
	  color-button-bg-hover: $primary-blue !important,
      color-button-text: $white !important,
      color-border: $white !important,
      color-arrow: $primary-green-lightest !important
    ),

    bg-gray: (
      color-headline: $white !important,
      color-offset: $white !important,
      color-text: $white !important,
      color-hover-text: $white  !important,
      color-button-bg: $secondary-blue-lightest !important,
	  color-button-bg-hover: $secondary-blue !important,
      color-button-text: $primary-blue !important,
      color-border: $white!important,
      color-arrow: $secondary-black-lighter !important
    ),

    bg-gray-light: (
      color-headline: $primary-blue !important,
      color-offset: $primary-blue !important,
      color-text: $secondary-black-light !important,
	  color-hover-text: $white  !important,
      color-button-bg: $primary-green !important,
	  color-button-bg-hover: $primary-blue !important,
      color-button-text: $white !important,
      color-border: $primary-blue !important,
      color-arrow: $secondary-black-lightest  !important
    ),

	bg-sand: (
      color-headline: $primary-blue !important,
      color-offset: $primary-blue !important,
      color-text: $secondary-black-light !important,
	  color-hover-text: $white  !important,
      color-button-bg: $primary-green !important,
	  color-button-bg-hover: $primary-blue !important,
      color-button-text: $white !important,
      color-border: $primary-blue !important,
      color-arrow: $secondary-black-lightest  !important
    ),

	bg-blue: (
        color-headline: $white !important,
        color-offset: $white !important,
        color-text: $white !important,
    	color-hover-text: $primary-blue !important,
        color-button-bg: $primary-blue !important,
	    color-button-bg-hover: $white !important,
        color-button-text: $white !important,
        color-border: $white !important,
        color-arrow: $white !important
    ),

	bg-blue-light: (
        color-headline: $primary-blue !important,
        color-offset: $primary-green !important,
        color-text: $secondary-black-light  !important,
		color-hover-text: $white !important,
        color-button-bg: $primary-green !important,
	    color-button-bg-hover: $primary-blue !important,
        color-button-text: $white !important,
        color-border: $primary-blue !important,
        color-arrow: $secondary-blue-lightest !important
    ),

	 bg-yellow-light: (
      color-headline:$secondary-orange!important,
      color-offset: $secondary-yellow !important,
      color-text: $secondary-black-light !important,
	  color-hover-text: $white !important,
      color-button-bg: $secondary-yellow !important,
	  color-button-bg-hover: $secondary-orange !important,
      color-button-text: $secondary-black-light !important,
      color-border: $secondary-yellow !important,
      color-arrow: $secondary-yellow-lightest !important
    ),

	bg-orange-light: (
      color-headline: $secondary-orange !important,
      color-offset: $secondary-orange !important,
      color-text: $secondary-black-light !important,
	  color-hover-text: $white !important,
      color-button-bg: $secondary-orange !important,
	  color-button-bg-hover: $secondary-red !important,
      color-button-text: $white !important,
      color-border: $secondary-orange !important,
      color-arrow: $secondary-orange-lightest !important
    ),

	bg-pink: (
      color-headline: $white  !important,
      color-offset: $white !important,
      color-text: $white!important,
	  color-hover-text: $white !important,
      color-button-bg: $secondary-pink-lightest !important,
	  color-button-bg-hover: $secondary-red !important,
      color-button-text: $white !important,
      color-border: $white!important,
      color-arrow: $white!important
    ),

	bg-pink-light: (
      color-headline: $secondary-pink  !important,
      color-offset: $secondary-pink !important,
      color-text: $secondary-black-light !important,
	  color-hover-text: $white !important,
      color-button-bg: $secondary-pink !important,
	  color-button-bg-hover: $secondary-red !important,
      color-button-text: $white !important,
      color-border: $secondary-pink !important,
      color-arrow: $secondary-black-lighter !important
    ),
	
	bg-red-light: (
      color-headline: $secondary-red  !important,
      color-offset: $secondary-red !important,
      color-text: $secondary-black-light !important,
      color-hover-text: $white !important,
      color-button-bg: $secondary-red !important,
	  color-button-bg-hover: $secondary-pink !important,
      color-button-text: $white !important,
      color-border: $secondary-red !important,
      color-arrow: $secondary-red-lightest !important
    ),
	bg-red: (
      color-headline: $white  !important,
      color-offset: $white  !important,
      color-text: $white  !important,
	  color-hover-text: $white !important,
      color-button-bg: $secondary-red-lightest !important,
	  color-button-bg-hover: $secondary-pink !important,
      color-button-text: $white !important,
      color-border: $secondary-red-lightest !important,
      color-arrow: $secondary-red-lightest !important
    ),

	bg-purple-light: (
      color-headline: $secondary-purple  !important,
      color-offset: $secondary-purple !important,
      color-text: $secondary-black-light !important,
	  color-hover-text: $white !important,
      color-button-bg: $secondary-purple !important,
	  color-button-bg-hover: $secondary-purple-lightest !important,
      color-button-text: $white !important,
      color-border: $secondary-purple-lightest !important,
      color-arrow: $secondary-purple-lightest !important
    ),	
	
	bg-purple: (
      color-headline: $white  !important,
      color-offset: $white !important,
      color-text: $white !important,
	  color-hover-text: $white !important,
      color-button-bg: $secondary-purple-lightest  !important,
	  color-button-bg-hover: $secondary-orange !important,
      color-button-text: $white !important,
      color-border: $secondary-purple !important,
      color-arrow: $secondary-purple !important
    ),	

	bg-brown: (
      color-headline: $white !important,
      color-offset: $white !important,
      color-text: $white !important,
	  color-hover-text: $white !important,
      color-button-bg: $secondary-brown !important,
	  color-button-bg-hover: $secondary-red !important,
      color-button-text: $white !important,
      color-border: $secondary-brown !important,
      color-arrow: $white !important
    ),

	bg-brown-light: (
      color-headline: $secondary-brown !important,
      color-offset: $secondary-brown !important,
      color-text: $white !important,
	  color-hover-text: $white !important,
      color-button-bg: $secondary-brown !important,
	  color-button-bg-hover: $secondary-red !important,
      color-button-text: $white !important,
      color-border: $secondary-brown !important,
      color-arrow: $secondary-brown-lightest !important
    ),
	   
	bg-white-red: (
        color-headline: $secondary-red !important,
        color-offset: $secondary-red  !important,
        color-text: $secondary-red!important,
		color-hover-text: $white !important,
        color-button-bg: $secondary-red !important,
	    color-button-bg-hover: $secondary-red-lightest !important,
        color-button-text: $white !important,
        color-border: $secondary-red!important,
        color-arrow: $white !important
    ),
	bg-white-lightblue: (
        color-headline: $secondary-blue !important,
        color-offset: $secondary-blue  !important,
        color-text: $secondary-blue!important,
	    color-hover-text: $white !important,
        color-button-bg: $white !important,
	    color-button-bg-hover: $secondary-blue !important,
        color-button-text: $secondary-blue !important,
        color-border: $secondary-blue!important,
        color-arrow: $secondary-blue!important
    ),
	bg-transparent-orange: (
        color-headline: $secondary-orange !important,
        color-offset: $secondary-orange-lightest  !important,
        color-text: $secondary-orange!important,
	    color-hover-text: $secondary-orange-lightest  !important,
        color-button-bg: $secondary-orange !important,
	    color-button-bg-hover: $secondary-orange-lightest !important,
        color-button-text: $white !important,
        color-border: $secondary-orange-lightest!important,
        color-arrow: $secondary-orange-lightest!important
    ),	
	bg-transparent-lightblue: (
		color-headline: $secondary-blue !important,
        color-offset: $secondary-blue  !important,
        color-text: $secondary-blue!important,
	    color-hover-text: $white !important,
        color-button-bg: $white !important,
	    color-button-bg-hover: $secondary-blue-lightest !important,
        color-button-text: $secondary-blue !important,
        color-border: $secondary-blue!important,
        color-arrow: $secondary-blue!important 
	),
	bg-transparent-blue: (
		color-headline: $primary-blue !important,
        color-offset: $primary-blue  !important,
        color-text: $primary-blue!important,
	    color-hover-text: $white !important,
        color-button-bg: $white !important,
	    color-button-bg-hover: $primary-blue-lightest !important,
        color-button-text: $primary-blue !important,
        color-border: $primary-blue!important,
        color-arrow: $primary-blue!important 
	),
	bg-transparent-red: (
        color-headline: $secondary-red !important,
        color-offset: $secondary-red  !important,
        color-text: $secondary-red!important,
		color-hover-text: $white !important,
        color-button-bg: $secondary-red !important,
	    color-button-bg-hover: $secondary-red-lightest !important,
        color-button-text: $white !important,
        color-border: $secondary-red!important,
        color-arrow: $white !important
    ),
	bg-transparent-purple: (
        color-headline: $secondary-purple !important,
        color-offset: $secondary-purple  !important,
        color-text: $secondary-purple !important,
		color-hover-text: $white !important,
        color-button-bg: $secondary-purple !important,
	    color-button-bg-hover: $secondary-purple-lightest !important,
        color-button-text: $white !important,
        color-border: $secondary-purple !important,
        color-arrow: $white !important
    ),

	 bg-transparent-green: (
      color-headline: $primary-green !important,
      color-offset: $primary-blue !important,
      color-text: $primary-green !important,
	  color-hover-text: $primary-green  !important,
      color-button-bg: transparent !important,
	  color-button-bg-hover: transparent !important,
      color-button-text: $primary-green !important,
      color-border: $primary-green !important,
      color-arrow: $primary-green !important
    )
);


@function map-fetch($map, $keys) {
    $key: nth($keys, 1);
    $length: length($keys);
    $value: map-get($map, $key);
    
    
    @if $value != null {
      @if $length > 1 {
        $rest: ();
        
        @for $i from 2 through $length {
          $rest: append($rest, nth($keys, $i))
        }
        
        @return map-fetch($value, $rest);
      } @else {
        @return $value;
      }
    } @else {
      @return false;
    }
  }
  
  @function getThemifyVariable($key) {
    @return map-get($theme-map, $key);
  }
  
  @mixin themify ($themes: $themes) {
    @each $theme, $map in $themes {
      .#{$theme} & {
        // Define theme color
        $theme-map : (
            color-alpha: $primary-blue
        ) !global;
        
        @each $key, $submap in $map {
          $value: map-fetch($themes, $theme '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
        
        @content;
        
        // reset theme color to null
        $theme-map: null !global;
      }
    }
  }
