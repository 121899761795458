.bg_image_container {
	
	img {		
		width: 100%;
		height: auto;
		margin-left: 0%;
	}
}

.bg_overlay {
	position: absolute;
	top: 52%;
	left: 50%;
	transform: translate(-50%, -50%);

	@include media-breakpoint-down(md) {
		position: relative;
		top: 0;
		left: 0;
		transform: none;
	}
}
