$teasers-box-overlay-bg-color: $white;
$teasers-box-overlay-text-color: $font-color-p;

.teasers-box {

	&__overlay {
		position: relative;
		margin-top: 3rem;
		background-color: $teasers-box-overlay-bg-color;


		&:before,
		&:after {
			content: '';
			position: absolute;
			border-bottom: 3rem solid $teasers-box-overlay-bg-color;
			top: -3rem;
			width: 50%;
		}

		&:before {
			right: 75%;
			width: 25%;
			border-right: 3rem solid transparent;
		}

		&:after {
			left: 25%;
			width: 75%;
			border-left: 3rem solid transparent;
		}

		@include media-breakpoint-up(lg) {
			margin-top: 0;
			background-color: inherit;
			position: absolute;
			top: 0;
			bottom: 0;

			&:before,
			&:after {
				content: none;
			}
		}
	}

	&__overlaybox {
		padding: 0;
		color: $teasers-box-overlay-text-color;
		background-color: $teasers-box-overlay-bg-color;
		-webkit-box-shadow: 0px 4px 6px 0px rgba(222,222,222,0.45);
		-moz-box-shadow: 0px 4px 6px 0px rgba(222,222,222,0.45);
		box-shadow: 0px 4px 6px 0px rgba(222,222,222,0.45);

		@include media-breakpoint-up(md) {
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}
		}
	}

	&__overlayinner {
		position: relative;
		width: 100%;
		border-top: 1px solid $teasers-box-overlay-bg-color;

		@include media-breakpoint-down(sm) {
			padding: 2rem;
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			border-bottom: 1.8rem solid $teasers-box-overlay-bg-color;
			top: -1.8rem;
			width: 50%;

			@include media-breakpoint-down(sm) {
				border-bottom: 1rem solid $teasers-box-overlay-bg-color;
				top: -1rem;
			}
		}

		&:before {
			right: 90%;
			width: 10%;
			border-right: 2rem solid transparent;

			@include media-breakpoint-down(sm) {
				border-right: 1rem solid transparent;
			}
		}

		&:after {
			left: 10%;
			width: 90%;
			border-left: 2rem solid transparent;

			@include media-breakpoint-down(sm) {
				border-left: 1rem solid transparent;
			}
		}
	}
}

.teasers-box-overlay {
	position: relative;

}

.container-fluid {
	.teasers-box {
		&__overlayinner {
			font-size: x-large;

			@include media-breakpoint-down(lg) {
				font-size: large;
				padding: 1.5rem;
			}

			@include media-breakpoint-down(md) {
				font-size: small;
				padding: 1.5rem;
			}

			@include media-breakpoint-down(sm) {
				font-size: small;
				padding: 1rem;
			}
		}
	}
}
