$blue:$primary-blue;
.image-box-content {

	&__headline {
		text-transform: uppercase;
		font-weight: bolder;
		color: $blue;
		display: flex;



		.hr-rule {
			width: 75%;
			height: 1px;
			background-color: $blue;
			flex: 1 1;
			margin: 12px;
		}
	}

	&__elements {
	}

	.card-columns {
		column-count: 1;

		@include media-breakpoint-down(sm) {
			column-count: 1;
		}

		@include media-breakpoint-only(md) {
			column-count: 2;
		}

		@include media-breakpoint-only(lg) {
			column-count: 2;
		}

		@include media-breakpoint-only(xl) {
			column-count: 4;
		}

		.card-body {
			width: 50%;

			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}

	.image-box {
		border-radius: 10px;
		padding: 20px !important;
		min-height: 150px;

		&.card {
			@include media-breakpoint-down(sm) {
				position: sticky;
			}
		}

		&.disabled-image-box {
			opacity: 0.2;
			cursor: default;
		}

		&__title {
			line-height: 1.2;
			font-size: 16px;
			font-weight: 600;
			line-height: 19.5px;

			@include media-breakpoint-down(md) {
				font-size: 20px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 16px;
			}
		}

		&__text {
			font-weight: 400;
		}

		&__icon {
			width: 40%;
			position: absolute;
			top: 20px;
			right: 20px;

			@include media-breakpoint-down(md) {
				display: none;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		&__link {
			position: absolute;
			bottom: 20px;
			left: 20px;
			font-size: 13px;
			font-weight: 600;
			line-height: 19.5px;

			@include themify() {
				color: getThemifyVariable('color-button-text');
			}

			i {
				padding-right: 5px;
			}

			a {
				color: #ffffff !important;
				padding-right: 10px;

				&:hover {
					@include themify() {
						color: getThemifyVariable('color-button-bg');
					}
				}
			}
		}
	}
}






