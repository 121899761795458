$meta-color: $secondary-black-light;
.events {
	&__upcoming {
		column-count: 3;

		.card-columns {
			column-count: 2;
		}

		a {
			text-decoration: none;
		}

		h2 {
			font-size: 2rem;
		}

		figure {
			img {
				width: 100%;
				height: auto;
				border-top-left-radius: calc(0.25rem - 1px);
				border-top-right-radius: calc(0.25rem - 1px);
			}
		}

		.card-body {
			span {
				color: $meta-color;
				font-size: 13px;
				font-weight: 600;
				text-transform: uppercase;
				display: block;
			}
		}
	}
}
