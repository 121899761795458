.linkribbon {
	padding: 5rem 0;
	position: relative;
	overflow: hidden;
	align-items: center;

	&__lefticon {

		@media (max-width: 992px) {
			/*position: absolute;
			top: -500px;
			min-width: 300px;
			height: auto;
			text-align: center;*/
		}

		img {
			position: absolute;
			top: 0;
			max-height: 100%;
			width: auto;


			@media (max-width: 992px) {
				position: absolute;
				margin: auto;
				left: 0;
				right: 0;
				text-align: center;
				z-index: 1;
				-webkit-transform: rotate(90deg);
				-moz-transform: rotate(90deg);
				-o-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
				top: -50%;
				width: auto;
				height: auto;
				
			}
		}
	}

	&__righticon {

		@media (max-width: 992px) {
		}

		img {
			position: absolute;
			top: 0;
			max-height: 100%;
			width: auto;


			@media (min-width: 992px) {
				right: 0;
			}

			@media (max-width: 992px) {
				position: absolute;
				margin: auto;
				left: 0;
				right: 0;
				text-align: center;
				z-index: 1;
				-webkit-transform: rotate(90deg);
				-moz-transform: rotate(90deg);
				-o-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
				width: auto;
				height: auto;
				top: 50%;
			}
		}
	}
}

.element_block_divider {
	z-index:1024;
	@include media-breakpoint-up(lg) {
		border-bottom: 0;

		&:after {
			border-bottom: 0 !important;
		}

		@include themify() {
			border-right: 1px solid getThemifyVariable('color-border');
		}
	}

	@media (max-width: 992px) {
		&:after {
			content: " ";
			top: 100%;

			@include themify() {
				border-bottom: solid 1px getThemifyVariable('color-border');
			}

			position: absolute;
			width: 30px;
			height: 0;
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
			margin: auto;
			left: 48%;
		}
	}

	@include media-breakpoint-up(lg) {
		border-bottom: 0;
	}

	&:last-child {
		border-right: 0 !important;
		border-bottom: 0 !important;

		&:before, &:after {
			display: none;
		}
	}
}

