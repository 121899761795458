#countrychooser {
	position: absolute;
	z-index:inherit;
	a {	
		text-decoration:none!important;		
	}
}
#close-countrychooser {
	border: none;
	background: none;
	position: absolute;
	right: 30px;
	z-index: inherit;
	top: 15px;
	font-size: 3rem;
	font-weight: 100;
}
.countrychooser-link {
	margin-right: 10px;
}

.countrychooser-elements {
	#visitorCountryText {
		margin-top: auto;		
		color: $white;

		p {
			color: $white;
		}
	}

	i {
		color: $white;
	}

	img {
		margin-right: 10px;
	}
}
