.social {
	&__heading {
		font-weight: 600;
		font-size: 18px;

		@include themify() {
			color: getThemifyVariable('color-headline');
		}
	}

	&__icons {
		a {
			text-decoration: none;

			@include themify() {
				color: getThemifyVariable('color-text');
			}

			&:hover {
				@include themify() {
					color: getThemifyVariable('color-button-text');
				}
			}
		}

		&__rounded {
			.fa-inverse {
				@include themify() {
					color: getThemifyVariable('color-arrow');
				}
			}
		}
	}

	.fa-inverse {
		@include themify() {
			color: getThemifyVariable('color-arrow');
		}
	}
}
