.video-teaser {
	width: 100%;
	height: auto;

}
.videohero-teaser{
	width:100%;
	height:100%;
}
.teasers-heroimage__overlaybox{
	min-width:270px;
}

.pagemode-edit {
	.video-teaser, .videohero-teaser {
		&.scEnabledChrome {
			padding: 3rem;
		}
	}
}
