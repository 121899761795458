$quote-default-color: $quote-default-color ;
.quote {
	$parent: &;
	padding: 20px 40px;
	position: relative;

	&:before {
		position: absolute;
		font-size: 6em;
		line-height: 1;
		top: 0;
		left: 0;
		content: "\201C";
		color: $quote-default-color;

		@include themify() {
			color: getThemifyVariable('color-headline');
		}
	}

	/*.fa-quote-left {
		margin-top: 7px;

		&:before {
			font-size: 4rem;
			color: $quote-default-color;

			@include themify() {
				color: getThemifyVariable('color-headline');
			}
		}
	}*/

	&__info {
		margin: 0;
		font-size: 1.5em;
		line-height: 1.5em;
		font-weight: 300;
		box-sizing: border-box;
		color: $quote-default-color;

		@include themify() {
			color: getThemifyVariable('color-headline');
		}
	}

	&__meta {
		display: block;
		margin-top: 15px;

		p {
			padding: 0;
			margin: 0;
			font-size: 14px;
			line-height: 1.4;
			color: $quote-default-color;

			@include themify() {
				color: getThemifyVariable('color-headline');
			}
		}
	}
}
