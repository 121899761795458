.list-header {
	display: block;
	padding: 0;
	margin: 0 0 28px;
	text-align: center;
	font-size: 24px;
	font-weight: 300;
}

.iframelist{	
		&_footer {
			text-align: center;
			margin-bottom: 20px;
		}
		&_btn {
		
		}
}

