$textarea-border-radius:0.2rem;
$background-color: $white;
$bg-white-bg-color: $white-beige;
$primary-green: $primary-green;
$secondary-red: $secondary-red;
$box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .22);
.corporate {
	padding: 2rem;

	h1 {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-weight: 300;
		font-size: $h2-font-size;
	}

	.form-sections {
		max-width: 100%;
	}

	.btn {
		border-radius: $btn-border-radius;

		@include themify() {
			background-color: getThemifyVariable('color-button-bg');
		}

		@include themify() {
			color: getThemifyVariable('color-button-text');
		}
	}

	.control-label {
		padding-right: 10px;
	}

	.form-group {
		select {
			margin-bottom: 2rem;
			border-radius: $textarea-border-radius;
			background-color: $background-color;
			box-shadow: $box-shadow;
		}

		input {
			margin-bottom: 2rem;
		}

		label {
			margin-bottom: 0.5rem;
			margin-top: 0.5rem;
		}

		textarea {
			max-width: 100%;
			min-height: 205px;
			resize: none;
			-webkit-appearance: none;
			border: 1px solid #f6f8fa;
			outline: none;
			vertical-align: middle;
			border-radius: $textarea-border-radius;
			background-color: $background-color;
			box-shadow: $box-shadow;
		}

		input[type=checkbox], input[type=radio] {
			margin-right: .5em;
		}
	}

	.form-control {
		margin: 0;
		display: inline-block;
		font-size: 16px;
		line-height: 22px;
		height: 40px;
		font-weight: 400;
		border: 1px solid #f6f8fa;
		outline: none;
		vertical-align: middle;
		border-radius: $textarea-border-radius;
		background-color: #ffffff;
		box-shadow: $box-shadow;
	}

	.text-box {
		margin: 0;
		display: inline-block;
		font-size: 16px;
		line-height: 22px;
		height: 34px;
		font-weight: 400;
		border: 1px solid #f6f8fa;
		outline: none;
		vertical-align: middle;
		border-radius: $textarea-border-radius;
		background-color: $background-color;
		box-shadow: $box-shadow;
	}

	.checkbox-list {

		label {
			display: block;
		}

		input[type="radio"], input[type="checkbox"] {
			margin: 3px;
		}
	}

	.field-validation-error {
		color: $secondary-red;
	}
}

.pardot-iframe-resize {
	min-width: 100%;
}

.page-header {
	p {
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}

	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

.form-submit-border {
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}
  
.input-field-for-contact {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	z-index: -1;
}

.text-field-for-input {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	z-index: -1;
}











