// Cookiepolicy
$background-color: $white-dark;
$hover-color: $primary-blue;

.cookiepolicy {
	background-color: $background-color;
	z-index: 10000;	
	display:none;
	
	.buttons {		
		a.cookie-accept {
			margin-right: 5px;
			margin-top: 0px;
			text-transform: uppercase;
			text-decoration: none;
		}

		a.cookie-accept:hover {
			background-color: $hover-color;
			color: $white;
		}		
	}
}

