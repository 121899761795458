﻿$linkColor: $primary-blue;
$linkColorHover: $primary-green;
$selectedFilterBackground: $primary-blue;
$selectedFilterFontColor: $white;
$filterHeadBackgroundColor: $primary-blue-lightest;
$filterHeadFontColor: $primary-blue;
$loadMoreBgColor: $primary-green;
$buttonBackgroundColorHover: $primary-blue;
$loadMoreColor: $white;
$borderRadius: 1.6rem;
$mainTagBgColor: $primary-blue;
$mainTagColor: $white;
$tagColor: $secondary-black-light;
$publishDateColor: $secondary-black-light;

.newsroom {

	&-results {

		&-item {
			text-decoration: none;
			color: $linkColor;
			display: flex;
			flex-wrap: wrap;

			h3 {
				@include media-breakpoint-down(lg) {
					font-size: 18px;
				}
			}

			&:hover {
				color: $linkColorHover;
				text-decoration: none;
			}

			&:hover .newsroom-results-item-tag {
				color: $linkColorHover;
			}

			&:hover .newsroom-results-item-publishdate {
				color: $linkColorHover;
			}

			img {
				width: 100%;
				max-width: 100%;
				height: auto;
			}

			&-publishdate {
				font-size: 16px;
				font-weight: 200;
				text-transform: uppercase;
				display: block;
				position: relative;
				color: $publishDateColor;
				padding-bottom: 1rem;
			}

			&-taglist {
				@include media-breakpoint-up(lg) {
					position: absolute;
					bottom: 5px;
				}
				/*@include media-breakpoint-down(lg) {*/
				display: flex;
				flex-wrap: wrap;
				/*}*/
			}

			&-maintag {
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 0px 0px $borderRadius 0px;
				padding: 5px 10px;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 600;
				background-color: $mainTagBgColor;
				color: $mainTagColor;
			}

			&-tag {
				color: $tagColor;

				&:after {
					content: "|";
					padding: 0 .5rem;
				}

				&:last-child {
					margin-right: 0px;

					&:after {
						content: "" !important;
						padding: 0 !important;
					}
				}
			}
		}
	}

	/*&-btn {

		&-loadmore {
			background-color: $loadMoreBgColor;
			color: $loadMoreColor;

			&:hover {
				background-color: $buttonBackgroundColorHover;
				color: $loadMoreColor;
			}
		}
	}*/

	/*&-sort {
		width: 100%;
	}*/

	/*.div-collapse {
		border-top: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.div-collapse {
		overflow-x: visible;
		-webkit-overflow-scrolling: touch;
		border-top: 1px solid transparent;
		-webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
		box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
	}

	@include media-breakpoint-up(lg) {
		.div-collapse.collapse {
			display: block !important;
			height: auto !important;
			overflow: visible !important;
		}
	}*/
}
