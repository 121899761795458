$heading-color: $primary-font-h1;
$abstract-color: $secondary-black-light;
$secondary-pale: $secondary-blue-pale;
$green: $primary-green;
$dark-blue: $secondary-blue-ch-dark;
$gray: $gray-ch-4;
$white: $white;



.content-headline {
	color: $heading-color;
	font-weight: 300;

	@include media-breakpoint-down(md) {
		padding-top: 1rem !important;
		font-size:3rem;
	}
}
.abstract-content {
	font-weight: 300;
	color: $abstract-color;
	font-size: 2.6rem;

	@include media-breakpoint-down(md) {		
		font-size: 2.2rem;
	}
}

.page-content {
	line-height: 24px;

	img {
		width: 100%;
		height: auto;
	}

	.adjusting {
		position: relative;

		a {
			position: absolute;
			top: -200px;
		}
	}


	li {
		color: $gray-font-color;

		@include themify() {
			color: getThemifyVariable('color-text');
		}

		line-height: normal;
		font-size: 16px;
	}

	ul li {
		list-style-type: none;
		position: relative;
		font-size: 16px;
		line-height: 24px;
	}

	ul, ol {
		margin: 1em;
		padding: 0 0 0 20px;

		@include media-breakpoint-down(sm) {
			margin: 0;
			padding: 1em;
		}
	}

	li:before {
		content: "\2022";
		position: absolute;
		left: -15px;
		font-size: 3rem;
	}


	ol {
		li:before {
			display: none;
		}
	}

	table {
		border-color: $gray;

		thead {
			background-color: $secondary-pale;
		}
	}

	.table {
		@include media-breakpoint-down(sm) {
			font-size: 12px;
		}

		&-gray {
			th {
				border: 1px solid $gray;
				padding: 2rem;
				border-right-color: $white;

				@include media-breakpoint-down(sm) {
					padding: 1rem;
					font-size: 12px;
					font-weight: 300;
				}

				&:last-child {
					border-right-color: $gray;
				}
			}

			td {
				border: 1px solid $gray;
				padding: 2rem;

				@include media-breakpoint-down(sm) {
					padding: 1rem;
					font-size: 12px;
				}
			}

			thead {
				background-color: $gray;
				color: $white;
				text-transform: uppercase;
			}
		}

		&-blue {
			th {
				border: 1px solid $gray;
				padding: 2rem;

				@include media-breakpoint-down(sm) {
					padding: 1rem;
					font-size: 12px;
					font-weight: 300;
				}
			}

			td {
				border: 1px solid $gray;
				padding: 2rem;

				@include media-breakpoint-down(sm) {
					padding: 1rem;
					font-size: 12px;
				}
			}

			thead {
				background-color: $dark-blue;
				color: $white;
				text-transform: uppercase;
			}
		}
	}

	.card {
		.card-title {
			font-size: 2rem;
			margin-top: 1.25rem;

			@include themify() {
				color: getThemifyVariable('color-headline');
			}
		}


		.card-subtitle {
			font-weight: 200;
		}

		.card-text {
			font-size: 16px;

			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}

		.card-link {
			&a {
				@include themify() {
					color: getThemifyVariable('color-button-bg');
				}

				&:hover {
					@include themify() {
						color: getThemifyVariable('color-button-bg-hover');
					}
				}
			}
		}
	}
}

aside {
	.page-content {
		ul, ol {
			margin: 0;
			padding: 1em;
		}
	}
}





