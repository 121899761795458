$gray-font-color: $font-color-p;
$secondary-blue-lightest: $secondary-blue-lightest;
$gray-light: $secondary-black-lightest;
$primary-font-color: $primary-green;
$primary-white: $white;
$primary-blue-color: $primary-blue;
$gray-color: $secondary-gray;
$h1-font-size: $h1-font-size;

.content-highlight {

	img {
		width: 100% !important;
		height: auto !important;
	}

	&.White {
		background-color: $primary-white;
		color: $gray-font-color;
	}

	&.Green {
		background-color: $primary-green-lightest;
		color: $gray-font-color;
	}

	&.Sand {
		background-color: $secondary-sand !important;
		color: $primary-blue !important;
	}

	&.Blue {
		background-color: $secondary-blue-lightest;
		color: $gray-font-color;
	}

	&.Dark-Green {
		background-color: $green;
		color: $primary-white;

		h1, h2, p, ol, li {
			color: $primary-white;
		}
	}

	&.Dark-Blue {
		background-color: $blue;
		color: $primary-white;

		h1, h2, p, ol, li {
			color: $primary-white;
		}
	}

	&.Gray {
		background-color: $gray-light;
	}

	h1, h2 {
		color: $gray-font-color;
		font-weight: 500;
	}

	h1 {
		font-size: $h1-font-size !important;
		font-weight: 300 !important;
	}

	h2 {
		font-size: $h2-font-size !important;
		font-weight: 300 !important;
		margin-top: 2rem;
	}

	li {
		color: $gray-font-color !important;
		line-height: normal;
		font-size: 16px;
	}

	ul li {
		list-style-type: none;
		position: relative;
		font-size: 16px;
		line-height: 24px;
		font-weight: 300;
	}

	ol li {
		font-size: 16px;
		line-height: 24px;
		font-weight: 300;
	}

	li:before {
		content: "\2022";
		position: absolute;
		left: -15px;
		font-size: 3rem;
		color: $gray-font-color;
	}

	ol {
		li:before {
			display: none;
		}
	}
}

article {
	.content-highlight {
		margin-bottom: 2rem;

		ul,ol {
			margin: 1em;
			padding: 0 0 0 20px;

			@include media-breakpoint-down(sm) {
				margin:0;
				padding: 1em;
			}
		}
	}
}

aside {
	.content-highlight {
		padding: 3rem !important;

		ul, ol {
			margin: 0;
			padding: 1em;
		}
	}
}
