$color-text: $font-color-p;
$h2-font-color: $primary-blue;

.contact-person {
	.img-fluid {
		max-width: 100%;
		height: auto;
	}

	h2 {
		font-size: $h2-font-size;
		font-weight: 500;
		clear: both;
		color: $h2-font-color;
		/*	@include themify() {
			color: getThemifyVariable('color-headline');
		}*/
	}

	p, address {
		font-size: 13px;
		color: $color-text;
		margin-bottom: 1px;
	}

	address {
		font-weight: 300;
	}

	.person_button {
		margin: 1rem auto;
		min-width: 100%;
		background-color: $primary-green;
		color: $white;
		/*@include themify() {
			background-color: getThemifyVariable('color-button-bg');
			color: getThemifyVariable('color-button-text');
		}*/

		&:hover {
			background-color: $primary-blue;
			color: $white;
			/*@include themify() {
				background-color: getThemifyVariable('color-button-bg-hover');
				color: getThemifyVariable('color-button-text');
			}*/
		}
	}
}


