$gray-light:#f6f8fa;
.form-styling {

	input[type=text],
	input[type=password],
	input[type=email],
	input[type=url],
	input[type=date],
	input[type=month],
	input[type=time],
	input[type=datetime],
	input[type=datetime-local],
	input[type=week],
	input[type=number],
	input[type=search],
	input[type=tel],
	input[type=color],
	select,
	textarea {
		padding: .5em 1em;
		margin-bottom: 1em;
		display: inline-block;
		font-family: inherit;
		color: $white;
		border: 1px solid $white;
		border-radius: 2em;
		background-color: transparent;
		outline: none;

		&::-webkit-input-placeholder {
			color: darken($white, 50%);
		}

		&::-moz-placeholder {
			color: darken($white, 50%);
			line-height: 16px;
			vertical-align: middle;
		}

		&:-ms-input-placeholder {
			color: darken($white, 50%);
		}

		&:focus {
			border-color: $green;
		}
	}

	input[type=submit] {
		padding: 1em;
		margin-bottom: 1em;
		display: inline-block;
		font-family: inherit;
		text-transform: uppercase;
		font-size: .75em;
		font-weight: 700;
		border-radius: 2em;
		color: $white;
		border-color: $green;
		background-color: $green;
		outline: none;

		&:focus,
		&:hover {
			background-color: $green;
			border: 1px solid $green;
		}
	}


	.checkbox-label,
	.radio-label {
		display: block;
		margin: .5em 0 1em;
		font-family: inherit;

		input[type=checkbox],
		input[type=radio] {
			margin-right: .5em;
		}
	}
}

.paging {
	width: 100%;

	ul {
		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;

		li {
			display: inline-block;
			list-style: none;
			padding: 0 5px;

			&:before {
				display: none;
			}

			a {
				color: $green;
				text-decoration: none;
				font-size: 18px;
				text-transform: uppercase;

				@include media-breakpoint-down(sm) {
					font-size: 16px;
				}
			}

			&.inactive {
				a {
					color: $gray;
					cursor: default;
				}
			}

			&.current {
				a {
					font-weight: bold;
				}
			}
		}
	}
}
