$location-module-background: $secondary-black-lightest;
$primary: $primary-blue;
$hover-link-color: $primary-green;

.location-module {

	.location {
		height: 100%;
		background-color: $location-module-background;

		.visitcard {
			padding: 30px;

			@include media-breakpoint-up(lg) {
				padding: 10px 30px;
			}

			@include media-breakpoint-up(xl) {
				padding: 30px;
			}

			a:hover {
				color: $hover-link-color;
			}

			i {
				padding-right: 0.4rem;
			}

			div {
				padding-bottom: 0.2rem;
			}
		}
	}

	.location-image {
		img {
			max-width: 100%;
			height: auto;
		}
	}
}

.location-headline {
	color:$primary;
	font-size: 36px;
	font-weight: 300;
	margin-bottom: 15px;
	clear: both;
	line-height: 1.15em;
}

.location-abstract {
	font-size: 22px;
	font-weight: 300;
	margin-bottom: 10px;
	clear: both;
	line-height: 1.5em;
}
