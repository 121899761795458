.product-recommendation {
	margin-top: map-get($spacers, 3)
}

.product-recommendation__question-container {
	display: flex;
	align-content: center;
	flex-wrap: wrap;	
}

.product-recommendation__question,
.product-recommendation__answer {
	width: 100%;
}

.product-recommendation__question {
	margin-bottom: $spacer;

	@include media-breakpoint-up(md) {
		margin-bottom: 0;
		width: 40%;
	}
	/*a:not([title=""]) {
		position: relative;
		display: block;
		cursor: pointer;

		&:before,
		&:after {
			font-size: $font-size-xs;
			position: absolute;
			opacity: 0;
			visibility: hidden;
			top: 100%;
			bottom: auto;
			left: 50%;
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			pointer-events: none;
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
			filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
			-webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
			-moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
			transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
		}

		&:before {
			z-index: 2;
			border: 6px solid transparent;
			background: transparent;
			content: "";
			margin-top: -11px;
			margin-bottom: 0;
			border-top-color: transparent;
			border-bottom-color: $secondary-blue-pale;
			margin-left: 25%;
		}

		&:after {
			z-index: 1;
			padding: ($spacer * 0.5);
			background-color: $secondary-blue-pale;
			color: $secondary-black;
			content: attr(title);
			width: $spacer * 20;
			text-align: center;
			text-decoration: none;
		}

		/*&:hover {
			&:before,
			&:after {
				visibility: visible;
				opacity: 1;
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
				filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
				-webkit-transform: translateY(12px);
				-moz-transform: translateY(12px);
				transform: translateY(12px);
			}
		}*/
	/*}*/
}

.product-recommendation__answer {
	position: relative;
	margin-bottom: $spacer * 2;

	@include media-breakpoint-up(md) {
		width: 60%;
	}
}

.product-recommendation__tool-tip {
	position: absolute;
	top: $spacer * 2.5;
	left: 9px;
	font-size: 1.3rem;
}

.product-recommendation__tool-tip--after {
	left: auto;
	right: 9px;
	
}

.product-recommendation__slider {
	width: 100%;
}

.product-recommendation__results {
	margin-bottom: map-get($spacers, 6);
}

.product-recommendation__results-title {
	margin: map-get($spacers, 3) 0;
	text-align: center;
	color: $primary-blue;
}

.product-recommendation__result-title {
	text-align: center;
	margin-bottom: ($spacer * 2);
}

.product-recommendation__result-item {
	transition: all 1s;
	overflow: hidden;
	opacity: 0;
	max-height: 0;
	font-size: $font-size-sm;
	text-align: center;

	&.is-recommended-starter,
	&.is-recommended-finisher {
		opacity: 1;
		max-height: none;

		a {
			display: block;
			max-width: 60%;
			margin: 1rem auto;
		}
	}

	img {
		margin: 0 auto $spacer;
		max-width: 20rem;
		width: 100%;
	}
}
