$teasers-heroimage-overlay-bg-color: $secondary-blue-dark;
$teasers-heroimage-overlay-text-color: $white !default;

.teasers-heroimage {
	&__image {
		display: inline;

		img {
			position: relative;
			width: 100%;
			height: auto;
		}
	}

	&__headline {
		font-size: $h1-font-size;
		font-weight: $font-weight-bold;
		color: $teasers-heroimage-overlay-text-color;

		@include media-breakpoint-up(md) {
			font-size: $h2-font-size;
		}

		@include media-breakpoint-up(lg) {
			font-size: $h1-font-size;
		}

		@include media-breakpoint-down(sm) {
			font-size: $h3-font-size;
		}

		span {
			font-weight: $font-weight-light;
			display: block;
		}
	}

	&__text {
		font-size: 1.2rem;
		color: $teasers-heroimage-overlay-text-color;

		@include media-breakpoint-up(md) {
			font-size: 1.2rem;
		}

		@include media-breakpoint-up(lg) {
			font-size: 1.6rem;
		}
	}

	&__button {
		margin-top: 0;
		width: 100%;

		@include themify() {
			background-color: getThemifyVariable('color-button-bg');
			color: getThemifyVariable('color-button-text');
		}

		@include media-breakpoint-up(md) {
			width: auto;
		}
	}

	&__overlay {
		position: relative;
		margin-top: 3rem;
		background-color: $teasers-heroimage-overlay-bg-color;
		width: 100%;

		&:before,
		&:after {
			content: '';
			position: absolute;
			border-bottom: 3rem solid $teasers-heroimage-overlay-bg-color;
			top: -3rem;
			width: 50%;
		}

		&:before {
			right: 75%;
			width: 25%;
			border-right: 3rem solid transparent;
		}

		&:after {
			left: 25%;
			width: 75%;
			border-left: 3rem solid transparent;
		}

		@include media-breakpoint-up(lg) {
			margin-top: 0;
			background-color: inherit;
			position: absolute;
			top: 0;
			bottom: 0;

			&:before,
			&:after {
				content: none;
			}
		}
	}

	&__overlaybox {
		padding: 0;
		color: $teasers-heroimage-overlay-text-color;

		@include media-breakpoint-up(md) {
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background-color: $teasers-heroimage-overlay-bg-color;
			}
		}

		@include media-breakpoint-down(sm) {
			background-color: $teasers-heroimage-overlay-bg-color;
		}
	}

	&__overlayinner {
		position: relative;
		padding: 3rem;

		@include media-breakpoint-down(sm) {
			padding: 1rem 2rem;
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			border-bottom: 1.9rem solid $teasers-heroimage-overlay-bg-color;
			top: -1.8rem;
			width: 50%;

			@include media-breakpoint-down(sm) {
				border-bottom: 1rem solid $teasers-heroimage-overlay-bg-color;
				top: -1rem;
			}
		}

		&:before {
			right: 90%;
			width: 10%;
			border-right: 2rem solid transparent;

			@include media-breakpoint-down(sm) {
				border-right: 1rem solid transparent;
			}
		}

		&:after {
			left: 10%;
			width: 90%;
			border-left: 2rem solid transparent;

			@include media-breakpoint-down(sm) {
				border-left: 1rem solid transparent;
			}
		}
	}
}

.teasers-overlay {
	position: absolute;
	bottom: -2.5rem;

	@include media-breakpoint-down(sm) {
		bottom: -4.5rem;
	}
}

.nesting-column_img {
	margin-left: -15px;
	margin-bottom: 4rem;

	@include media-breakpoint-down(md) {
		margin-bottom: 6rem;
	}
}

.pagemode-edit {
	.teasers-heroimage__overlaybox {
		background-color: $teasers-heroimage-overlay-bg-color;
	}

	.teasers-heroimage__overlaybox:before {
		display: none;
		background-color: $teasers-heroimage-overlay-bg-color;
	}

	teasers-heroimage__image {
		img {
			min-height: 150px;
			min-width: 150px;
		}
	}
}
