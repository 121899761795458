.factbox {
	padding: 2rem 3rem;

	@media (max-width: 768px) {
		padding: 2rem 1rem;
	}

	border-style: solid;
	border-width: 1px;

	@include themify() {
		border-color: getThemifyVariable('color-headline');
	}

	&-header {
		display: flex;
		flex-direction: row;

		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		.hr-rule {
			width: 75%;
			height: 1px;

			@include themify() {
				background-color: getThemifyVariable('color-headline');
			}

			flex: 1 1;
			margin: 12px;
		}
	}

	&-content {
		h1, h2, h3 {
			margin-top: 0;

			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}

		ul {			
			li {
				@include themify() {
					color: getThemifyVariable('color-text');
				}
			}
		}
		ol {			
			li {
				@include themify() {
					color: getThemifyVariable('color-text');
				}
			}
		}

		p {
			@include themify() {
				color: getThemifyVariable('color-text');
			}
		}
	}
}
