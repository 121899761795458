
.image-wrap {
	display: inline-block;
	position: relative;

	img{
		width:100%;
		height:auto;
	}


	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: white;
		opacity: 1;
		transition: opacity .5s ease;
	}

	&:hover .overlay {
		opacity: 0.57;
	}

	.blue-overlay {
		background: rgba(0,18,51,.75);

		i {
			position: absolute;
			top: 45%;
			left: 45%;			
			font-size: 5rem;			
			-webkit-transition: all 0.25s ease-in;
			transition: all 0.25s ease-in;
		}
	}
}
