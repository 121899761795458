$linkColor: $primary-blue;
$linkColorHover: $primary-green;
$selectedFilterBackground: $primary-blue;
$selectedFilterFontColor: $white;
$filterHeadBackgroundColor: $secondary-blue-ch-lightest;
$filterHeadFontColor: $secondary-black;
$loadMoreBgColor: $primary-green;
$buttonBackgroundColorHover: $primary-blue;
$loadMoreColor: $white;
$borderRadius: 1.6rem;
$mainTagBgColor: $primary-blue;
$mainTagColor: $white;
$tagColor: $secondary-black-light;
$publishDateColor: $secondary-black-light;
$borderColor: $primary-blue;

.contentSearch {

	&-dropdown {
		width: 100%;
		text-align: left;
		color: #000000;
		padding: 0.375rem 2.5rem;
		border: 1px solid $borderColor;
		margin-bottom: .3rem;
		border-radius: $borderRadius;
	}

	&-numberofresults {
		font-size: 1.5rem;
	}



	#contentSearch-filters {
		border: none;
		-webkit-box-shadow: none;
		box-shadow: none;

		& > .dropdown {
			margin-bottom: 1rem;

			& > a:focus {
				box-shadow: none;
			}

			& > .dropdown-menu {
				width: 88%;
				margin-left: 2px;
				height: auto;
				max-height: 215px;
				overflow-x: hidden;
			}
		}
	}

	#contentSearch-filter-month > div > ul > li > a {
		text-transform: capitalize;
	}

	#contentSearch-search-button {
		border-radius: 0 $borderRadius $borderRadius 0;
		border: 1px solid $borderColor;
		border-left: none;
		background-color: transparent !important;
		padding: .25rem 2.5rem .25rem 1rem;

		& > i {
			padding-left: .5rem;
			color: $borderColor;
		}
	}

	ul {
		margin-top: 2rem;
		list-style: none;
		padding: 0;
	}

	ul > li {
		/*font-size: 16px;*/
		@include media-breakpoint-only(lg) {
			font-size: 12px;
		}

		a {
			color: $linkColor;
		}

		a:hover {
			color: $linkColorHover;
		}
	}

	&-term {
		padding-left: 2.5rem;

		&:focus-visible {
			outline: 0;
			box-shadow: none;
			border-color: $borderColor;
		}

		&-container {

			input {
				border: 1px solid $borderColor;
				border-right: none;
				border-radius: $borderRadius;
				width: 100%;
				text-align: left;
				color: #000000;
				margin-bottom: .3rem;
			}

			a:hover {
				text-decoration: none;
				color: $linkColor;
			}
		}
	}

	&-filter-results {
		/*display: none;*/

		@include media-breakpoint-down(md) {
			padding: .5rem 2.5rem;
			border: 1px solid $filterHeadBackgroundColor;
			background-color: $filterHeadBackgroundColor;
			color: $filterHeadFontColor;
			border-radius: $borderRadius;
			cursor: pointer;
			display: block;
			font-size: 20px;
		}

		&:hover {
			@include media-breakpoint-down(md) {
				border-color: $filterHeadBackgroundColor;
				background-color: $filterHeadBackgroundColor;
				color: $filterHeadFontColor;
			}
		}
		/*&::after {
			@include media-breakpoint-down(md) {
				content: "\f106";
				float: right;
				font-family: "FontAwesome";
			}
		}

		&[aria-expanded="false"]::after {
			@include media-breakpoint-down(md) {
				content: "\f107";
			}
		}*/
	}

	&-filterhead {
		@include media-breakpoint-down(md) {
			border: 1px solid $filterHeadBackgroundColor;
			background-color: $filterHeadBackgroundColor;
			margin-bottom: .5rem;
			padding: .5rem;
			cursor: pointer;
		}

		&::after {
			/*@include media-breakpoint-down(md) {*/

			content: "\f106";
			float: right;
			font-family: "FontAwesome";
			/*}*/
		}

		&[aria-expanded="False"]::after {
			/*@include media-breakpoint-down(md) {*/
			content: "\f107";
			/*}*/
		}
	}

	&-filterbody {
		@include media-breakpoint-up(lg) {
			display: block !important;
		}
	}

	&-selected-filters {
		display: flex;
		flex-wrap: wrap;
		column-gap: 1rem;
		min-height: 36px;

		span {
			border: 1px solid $selectedFilterBackground;
			border-radius: $borderRadius;
			background-color: $selectedFilterBackground;
			color: $selectedFilterFontColor;
			padding: .375rem 1rem;
			cursor: pointer;
			margin-bottom: 1rem;
		}

		span > i {
			margin-left: 1rem;
			margin-right: .1rem;
			margin-top: .3rem;

			&:before {
				content: "X";
				font-family: "Verdana";
				font-style: normal;
				text-shadow: 2px 0 3px #000000;
			}
		}
	}

	&-clear-selected-filters {
		button {
			border: none;
			border-left: 1px solid $selectedFilterBackground;
			background-color: $selectedFilterFontColor;
			text-transform: uppercase;
			color: $selectedFilterBackground;
			padding: .375rem 0 .375rem 1rem;
			margin: 0 0 1rem 0;
			float: right;

			&:hover {
				border-left: 1px solid $loadMoreBgColor;
				color: $loadMoreBgColor;
			}
		}
	}

	&-btn {

		&-loadmore {
			background-color: $loadMoreBgColor;
			color: $loadMoreColor;

			&:hover {
				background-color: $buttonBackgroundColorHover;
				color: $loadMoreColor;
			}
		}
	}

	.div-collapse {
		border-top: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.div-collapse {
		overflow-x: visible;
		-webkit-overflow-scrolling: touch;
		border-top: 1px solid transparent;
		-webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
		box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
	}

	@include media-breakpoint-up(lg) {
		.div-collapse.collapse {
			display: block !important;
			height: auto !important;
			overflow: visible !important;
		}
	}

	.is-flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
