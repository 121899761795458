$navbar-inpage-color: $white !default;
$navbar-inpage-hover-color: $primary-green !default;
$navbar-inpage-bg-color: $primary-blue !default;
$navbar-inpage-padding: 1rem !default;

.inpage-navbar {
	z-index: 1025;
	

	@include media-breakpoint-up(lg) {
		right: 0;
		left: auto;
		bottom: auto;
		top: 49vh;
		position: fixed;
		height: auto;
	}

	@include media-breakpoint-down(md){
		display:none;
	}

	&__list {
		
		flex-direction: row;
		width: 100%;

		@include media-breakpoint-up(lg) {
			flex-direction: column;
			width: 4.5rem;
		}
	}

	&__item {
		padding: $navbar-inpage-padding;
		background-color: $navbar-inpage-bg-color;
		flex-grow: 1;
		text-align: center;

		@include media-breakpoint-up(lg) {
			padding: $navbar-inpage-padding*2 $navbar-inpage-padding/5 $navbar-inpage-padding $navbar-inpage-padding/2;
			margin-bottom: $navbar-inpage-padding;
		}
	}

	&__link {
		color: $navbar-inpage-color;
		transition: color 0.2s linear;

		@include media-breakpoint-up(lg) {
			writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl; /* IE 11 */
			text-orientation: sideways;
			transform: rotate(180deg);
			-ms-transform: rotate(180deg); /* IE  */
			-webkit-transform: rotate(180deg); /* Safari 3-8 */
		}

		&:hover {
			text-decoration: none;
			color: $navbar-inpage-hover-color;
		}
	}
}
