.image_text_link {
	margin-bottom: 1rem;

	&-headline {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-size: 22px;
		font-weight: 300;
	}

	&-text {
		@include themify() {
			color: getThemifyVariable('color-text');
		}

		font-size: 16px;
		line-height: 24px;
	}


	a {
		text-decoration: none;
	}

	.link_img {
		max-width: 100%;
		height: auto;
	}
}
