$blue:$primary-blue;
.article-image {
	display: inline-block;

	img {
		max-width: 100%;
		height: auto;
	}

	figcaption {
		box-sizing: border-box;
		width: 0px;
		min-width: 100%;
		margin: .5em 0;
		font-style: italic;
		font-size: .8em;
		line-height: 1.5em;
		color: $blue;
	}


	&.align-left {
		margin-right: 0;
		float: unset;

		@include media-breakpoint-up(md) {
			margin-right: 2rem;
			float: left;
			
		}
	}

	&.align-center {
		@include media-breakpoint-up(md) {
			display: table;
			margin: auto;
		}

		figcaption {
			@include media-breakpoint-up(md) {
				text-align: center;
				display: table-caption;
				caption-side: bottom;
			}
		}
	}

	&.align-right {
		margin-left: 0;
		float: unset;

		@include media-breakpoint-up(md) {
			margin-left: 2rem;
			float: right;
		
		}
	}
}

.container {
	.equal {
		.article-image {
			display: table;
			padding: 2rem;

			@include media-breakpoint-up(md) {
				display: inline-block;
			}

			&.align-left {
				margin: auto;

				@include media-breakpoint-up(md) {
					margin: 4rem;
				}
			}

			&.align-center {
				display: table;
				padding-top: 5rem;
				padding-bottom:0;

				@include media-breakpoint-up(md) {
					margin: auto;
				}
			}

			&.align-right {
				margin: auto;

				@include media-breakpoint-up(md) {
					margin: 4rem;
				}
			}
		}
	}
}
	/*.align-left, .align-right {
	img {
		width: 100%;
		height: auto;
		margin: .5em 0;
	}	
}*/







