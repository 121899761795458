.teasers-hero {

	&__container {
		margin: 0 auto;
		text-align: center;
		max-width: 80vw;
		padding: map-get($spacers, 17) 0 map-get($spacers, 10);

		@include media-breakpoint-up(lg) {
			max-width: 50vw;
		}

		@include media-breakpoint-down(sm) {
			padding: map-get($spacers, 7) 0 map-get($spacers, 5);
		}
	}

	&__text {

		@include themify() {
			color: getThemifyVariable('color-text');
		}

		font-size: $h5-font-size;
	}

	&__headline {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		font-weight: 300;
		font-size: $h1-font-size;
	}
}
