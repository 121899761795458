$headline-color: $primary-blue;
$text-color: $secondary-black-light;

.relatedlist {
	a {
		text-decoration: none;
	}

	h3 {		
		
		margin-bottom: 10px;
		clear: both;
		color: $headline-color;
	}

	&__item {
		img {
			display: block;
			width: 100%;
			max-width: 100%;
			height: auto;
			margin-left: 0 !important;
		}
	}

	.content {
		.meta {
			font-size: 9px;
			font-weight: 600;
			text-transform: uppercase;
			display: block;
			position: relative;		
			color: $text-color;
		}

		.header {
			color: $headline-color;
			font-size: 15px;
			font-weight: 500;
			height: 42px;
			line-height: 16px;
		}

		.text {
			color: $text-color;
			font-size: 12px;
			line-height:15px;
		}
	}
}
