@import "Feature-Video-VideoList-form";
@import "Feature-Video-VideoList-item";
@import "Feature-Video-VideoList";
@import "_Feature-Video-VideoModule";
@import "_Feature-Video-VideoMoa";


$background-color: $secondary-black-lighter;

.videobox {
	&__content {
		overflow: hidden;
	}

	iframe {
		width: 100%;
		height: 100%;
	}

	.img-thumbnail {
		width: 100% !important;
	}

	.videobox_bg {
		width: 100%;
		min-height: 200px;
		background-color: $background-color;
	}
}

.pagemode-edit {
	.videobox {
		padding: 5px;
	}
}
article{
	.videobox{		
		margin-bottom:1.5rem;
	}
}


