﻿$tagcolor: $primary-blue;

.topics {
	&-list {
		display:flex;
		flex-wrap: wrap;
	}
	&-tag {
		border: 1px solid $tagcolor;
		border-radius: 1.6rem;
		padding: .375rem .75rem;
		margin: .5rem;
		color: $tagcolor;

		&:hover {
			color: $white;
			background-color: $tagcolor;
			text-decoration: none;
		}
	}
}
