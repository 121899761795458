﻿$background-color: $white-dark;
$item-active-color: $primary-green;
$item-color: #4a4a4a;
$icon-color: $primary-blue;
$icon-active-color: $primary-green;

.navbar-quicklinks {
	display: block;

	h3 {		
		font-size: 2rem;
		font-weight: 500;
		clear: both;
		line-height: 1.375em;

		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		margin-bottom: 1rem;
	}
}

.nav-quicklinks {
	flex-direction: column !important;
	font-size: 1.3rem;
	line-height: 1.5rem;
	text-decoration: none;

	&-item {
		margin-bottom: 1rem;
		display: inline;



		.active {
			color: $item-active-color;
		}

		.active:hover {
			color: $item-active-color;
		}
	}

	a {
		color: $item-color;
		text-decoration: none;
	}

	a:hover {
		color: $item-active-color;
		text-decoration: none;
	}

	&-text {
		display: table-cell;
	}

	&-icon {
		color: $icon-color;
		font-weight: 100;
		display: table-cell;

		active {
			color: $icon-active-color;
		}
	}
}

.static-quicklinks {
	h3 {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}
		font-size:16px;
		padding-left: 20px;
		padding-bottom: 15px;
		font-weight: 600;
	}

	li {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}

		padding-left: 0;
		padding-bottom: 10px;

		a {
			font-size:14px;
			@include themify() {
				color: getThemifyVariable('color-headline');
			}
		}
	}
}
