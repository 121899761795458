.faqbox {
	$parent: &;

    padding: map-get($spacers, 10) 0;

	&__headline {
		@include themify() {
			color: getThemifyVariable('color-headline');
		}
		font-weight: 300;
		font-size: $h2-font-size;
		text-align: center;
		margin-bottom: 2rem;
	}

	&__list {
		font-size: $font-size-lg;
		list-style: none;
		padding: 0;
    	margin: 0;
	}

	&__item {
		&:first-child {
			#{$parent}__link {
				border-width: 1px 0 0;
				&.collapsed {
					border-width: 1px 0;
				}
				@include themify() {
					border-color: getThemifyVariable('color-border');
				}
			}
		}
	}

	&__link {
		display: flex;
		justify-content: space-between;
		width: 100%;
		border-radius: 0;
		@include themify() {
			color: getThemifyVariable('color-headline');
		}
		padding: 10px 0;
		
		.fa {
			margin-left: 5px;
		}
		border-width: 0 0 0 0;
		&.collapsed {
			border-width: 0 0 1px 0;
			@include themify() {
				border-color: getThemifyVariable('color-border');
			}
		}

		&:hover {
			// @include themify() {
			// 	background-color: getThemifyVariable('color-offset');
			// }
			text-decoration: none;
		}
		&:focus {
			text-decoration: none;
		}
		&--cta {
			white-space: nowrap;
			margin-left: 5px;
		}
	}

	&__text {
		@include themify() {
			color: getThemifyVariable('color-text');
		}
		font-size: $font-size-sm;
		margin-bottom: 0;
		padding: $spacer 0;
		&.show, &.collapsing {
			@include themify() {
				border-bottom: 1px solid getThemifyVariable('color-border');
			}
		}
	}
}
